import { defineStore } from 'pinia'

export const useFinancingFlowStore = defineStore('financingFlow', {
  state: () => ({
    financingFlow: null as any | null,
  }),
  actions: {
    setFinancingFlow(data: any) {
      this.financingFlow = data
    },
  },
})
