const NON_DIGIT_REGEX = /\D+/g
const REPEATED_DIGITS_REGEX = /^(\d)\1+$/
const CPF_LENGTH = 11
const FIRST_CHECK_DIGIT_WEIGHT = 10
const SECOND_CHECK_DIGIT_WEIGHT = 11

function calculateDigit(base: string, initialWeight: number): number {
  let sum = 0
  for (let i = 0; i < base.length; i++) {
    sum += Number.parseInt(base.charAt(i)) * (initialWeight - i)
  }
  const remainder = sum % 11
  return remainder < 2 ? 0 : 11 - remainder
}

export function validateCPF(cpf: string): boolean {
  const sanitizedCPF = cpf.replace(NON_DIGIT_REGEX, '')

  if (
    sanitizedCPF.length !== CPF_LENGTH
    || REPEATED_DIGITS_REGEX.test(sanitizedCPF)
  ) {
    return false
  }

  const base = sanitizedCPF.substring(0, 9)
  const firstCheckDigit = calculateDigit(base, FIRST_CHECK_DIGIT_WEIGHT)
  if (firstCheckDigit !== Number.parseInt(sanitizedCPF.charAt(9))) {
    return false
  }

  const fullBase = base + firstCheckDigit
  const secondCheckDigit = calculateDigit(fullBase, SECOND_CHECK_DIGIT_WEIGHT)
  if (secondCheckDigit !== Number.parseInt(sanitizedCPF.charAt(10))) {
    return false
  }

  return true
}
