import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import CompanyRegisterService from '~/services/company-register/CompanyRegister'
import CustomerRegisterService from '~/services/customer-register/CustomerRegister'

export interface UseCustomerParams {
  id: string
  projectPersonType: string
}

export function useCustomer(params: Ref<UseCustomerParams>) {
  const queryKey = computed(() => queryKeys.CUSTOMER.DATA(params.value))

  const queryFn = async () => {
    const customerRegisterService = new CustomerRegisterService(useApi('customerRegister'))
    const response = await customerRegisterService.get_customer_registration_form(params.value.id, params.value.projectPersonType)

    return response.data
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 3,
    retryDelay: 1000,
    staleTime: 1000 * 60 * 5, // 5 minutes
  })
}

export function useCompany(params: Ref<UseCustomerParams>) {
  const queryKey = computed(() => queryKeys.CUSTOMER.DATA(params.value))

  const queryFn = async () => {
    const companyRegisterService = new CompanyRegisterService(useApi('companyRegister'))
    const response = await companyRegisterService.get_company_registration_form(params.value.id, params.value.projectPersonType)
    return response.data
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 3,
    retryDelay: 1000,
    staleTime: 1000 * 60 * 5, // 5 minutes
  })
}
