import type { ModelTypes } from './__generated__/zeus'
import { SectionStatus } from './__generated__/zeus'

// TODO: Move this logic to bff
export function isFormalizationApproved(financingStatuses: ModelTypes['Financing']['section_statuses']): boolean {
  const formalizationStatus = financingStatuses.formalization

  return formalizationStatus?.registration === SectionStatus.approved
    && formalizationStatus.documentation === SectionStatus.approved
    && formalizationStatus.hardware === SectionStatus.submitted
    && (formalizationStatus.receipt_model === SectionStatus.submitted || formalizationStatus.receipt_model === SectionStatus.approved)
    && formalizationStatus.contract === SectionStatus.approved
};

export function isStageReproved(value: ModelTypes['SectionStatus'] | ModelTypes['FormalizationStatus']): string {
  Object.entries(value).forEach(([key, status]) => {
    if (status === 'reproved')
      return key
  })
  return ''
}

export function isFinancingStatusFinal(status: string): boolean {
  const statusList = ['canceled', 'reproved', 'expired']
  return statusList.includes(status)
}
