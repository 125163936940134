import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { FinancingStatus, FinancingSteps } from '~/services-v2/financial-bff/__generated__/zeus'
import financingBff from '~/services-v2/financial-bff/financial-bff'

export interface UseFinancingsParams {
  query: string
  page: number
  partner_id: number | string
  steps: FinancingSteps[]
  status: FinancingStatus[]
  is_combo: string
  page_size: number
  order_by: string
}

export {
  FinancingStatus,
  FinancingSteps,
}

export default function useFinancings(params: Ref<UseFinancingsParams>) {
  const queryKey = ref(queryKeys.FINANCINGS.LIST(params.value))

  const queryFn = async () => {
    const res = await financingBff('query')({
      financings: [{
        input: {
          is_combo: params.value.is_combo,
          order_by: params.value.order_by,
          page: params.value.page,
          page_size: params.value.page_size,
          partner_id: params.value.partner_id.toString(),
          query: params.value.query,
          status: params.value.status,
          steps: params.value.steps,
        },
      }, {
        data: {
          id: true,
          customer_name: true,
          financed_value: true,
          step: true,
          status: true,
          project_id: true,
          document: true,
          expires_at: true,
          created_at: true,
          formalized_at: true,
          installed_at: true,
          is_combo: true,
          flow_provider: true,
          person_type: true,
          all_payments_sent: true,
          updated_at: true,
          is_expired: true,
          system_power: true,
          new_journey: true,
        },
        total: true,
        total_by_step: {
          simulation: true,
          formalization: true,
          installation: true,
          expired: true,
          concluded: true,
        },
        total_by_status: {
          pending: true,
          expired: true,
          reproved: true,
          approved: true,
          under_analysis: true,
          pendency: true,
          send_contract: true,
          pending_customer: true,
          pending_receipt: true,
          concluded: true,
          canceled: true,
          kit_pending: true,
        },
      }],
    })

    return res.financings
  }

  const query = useQuery({
    queryKey,
    queryFn,
    staleTime: 1000 * 30,
  })

  watch(params, () => {
    queryKey.value = queryKeys.FINANCINGS.LIST(params.value)
  }, { deep: true })

  return query
}

export type FinancingsList = ReturnType<typeof useFinancings>['data']
