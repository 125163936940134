import type { SimulatorService } from '~/services/simulator-v2/simulator.types'
import { useMutation } from '@tanstack/vue-query'

import financingBff from '~/services-v2/financial-bff/financial-bff'
import { queryKeys } from '../../queryClient'

export default function useAnalysisBill() {
  const mutationKey = queryKeys.ANALYSIS_BILL

  async function mutationFn(params: SimulatorService.PayloadAnalyseEnergyBill) {
    const response = await financingBff('mutation')({
      processEnergyAccountAnalysis: [{
        params,
      }, {
        decision: true,
        details: true,
      }],
    })
    return response.processEnergyAccountAnalysis
  }

  return useMutation({
    mutationFn,
    mutationKey,
    retry: 3,
    retryDelay: 2000,
  })
}
