import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useInstallationDocument(projectId: Ref<string>) {
  const queryKey = queryKeys.INSTALLATIONS.DETAILS(projectId)

  const fetchInstallationDocument = async () => {
    const res = await financialBff('query')({
      installation: [{ projectId: projectId.value }, {
        id: true,
        project_id: true,
        has_ampera: true,
        has_ampera_activated: true,
        has_minimal_projects: true,
        project_name: true,
        new_journey: true,
        plant_id: true,
        plant_url: true,
        comment: true,
        concluded_at: true,
        grade: true,
        sections: {
          id: true,
          type: true,
          status: {
            description: true,
            id: true,
            slug: true,
          },
          documents: {
            filename: true,
            filetype: true,
            id: true,
            status: {
              description: true,
              id: true,
              slug: true,
            },
            type: true,
            url: true,
          },
        },
        status: {
          description: true,
          id: true,
          slug: true,
        },
      }],
    })
    return res.installation
  }

  return useQuery({
    queryKey,
    queryFn: fetchInstallationDocument,
    retry: 0,
  })
}
