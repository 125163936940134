import { useQuery } from '@tanstack/vue-query'
import KnowError from '~/exceptions/KnownError'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'
import { isFinancingStatusFinal, isFormalizationApproved } from '~/services-v2/financial-bff/financial-bff.utils'

export default function useFinancingStatus(id: Ref<string>) {
  const enabled = ref(true)
  const queryKey = queryKeys.FINANCINGS.STATUS(id)

  const fetchFinancingStatus = async () => {
    const response = await financialBff('query')({
      financing: [{ id: id.value }, {
        id: true,
        status: true,
        step: true,
        person_type: true,
        section_statuses: {
          concluded: true,
          simulation: true,
          formalization: {
            contract: true,
            documentation: true,
            hardware: true,
            receipt_model: true,
            registration: true,
          },
        },
      }],
    })

    if (!response.financing) {
      enabled.value = false
      throw new KnowError('NO_FINANCING_DATA')
    }

    const formalizationIsApproved = isFormalizationApproved(response.financing.section_statuses)
    const isFinancingFinal = isFinancingStatusFinal(response.financing.status)

    if (formalizationIsApproved || isFinancingFinal) {
      enabled.value = false
    }

    return {
      ...response.financing,
      isFormalizationApproved: formalizationIsApproved,
    }
  }

  return useQuery({
    queryKey,
    queryFn: fetchFinancingStatus,
    retry: 3,
    retryDelay: 1_000,
    staleTime: 10_000,
    refetchInterval: 10_000,
    refetchIntervalInBackground: false,
    enabled,
  })
}
