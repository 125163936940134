import axios from 'axios'

import { BASE_URL } from './keycloack-api.contants'
import { injectAccessTokenInterceptor, refreshTokenResponseInterceptor } from './keycloack-api.interceptors'

const http = axios.create({
  baseURL: BASE_URL,
})

http.interceptors.request.use(injectAccessTokenInterceptor)
http.interceptors.response.use(_ => _, refreshTokenResponseInterceptor)

export default http
