/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Order: "enum" as const,
	Person:{

	},
	Mutation:{
		customer:{

		},
		company:{
			majority_partner:"Person",
			partners:"Person"
		},
		processEnergyAccountAnalysis:{
			params:"AnalyzeEnergyBillInput"
		}
	},
	PersonType: "enum" as const,
	FinancingStatus: "enum" as const,
	SectionStatus: "enum" as const,
	FinancingSteps: "enum" as const,
	FinancingsInput:{
		steps:"FinancingSteps",
		status:"FinancingStatus"
	},
	Query:{
		financing:{

		},
		financings:{
			input:"FinancingsInput"
		},
		financing_type:{

		},
		installation:{

		},
		legacyFinancing:{

		},
		partners:{
			input:"CoreGetPartnersInput"
		},
		project:{

		},
		getOpportunities:{
			params:"GetOpportunitiesInput"
		}
	},
	FileStatusType: "enum" as const,
	DocumentStatusType: "enum" as const,
	TypeSection: "enum" as const,
	CoreGetPartnersInput:{

	},
	AnalyzeEnergyBillInput:{

	},
	GetOpportunitiesInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	CustomerUpdateResponse:{
		message:"String",
		status:"Int"
	},
	CompanyUpdateResponse:{
		message:"String",
		status:"Int"
	},
	Mutation:{
		customer:"CustomerUpdateResponse",
		company:"CompanyUpdateResponse",
		processEnergyAccountAnalysis:"EnergyBillAnalysisDecision"
	},
	FormalizationStatus:{
		contract:"SectionStatus",
		hardware:"SectionStatus",
		registration:"SectionStatus",
		documentation:"SectionStatus",
		receipt_model:"SectionStatus"
	},
	SectionStatuses:{
		concluded:"SectionStatus",
		simulation:"SectionStatus",
		installation:"SectionStatus",
		formalization:"FormalizationStatus"
	},
	Payment:{
		id:"Int",
		financing_id:"Float",
		value:"Float",
		total_value:"Float",
		payment_percentage:"Float",
		created_at:"String"
	},
	Financing:{
		id:"Int",
		project_id:"String",
		financed_value:"Float",
		customer_name:"String",
		document:"String",
		expires_at:"String",
		created_at:"String",
		formalized_at:"String",
		installed_at:"String",
		status:"FinancingStatus",
		step:"String",
		is_combo:"Boolean",
		payments:"Payment",
		flow_provider:"String",
		person_type:"PersonType",
		all_payments_sent:"Boolean",
		section_statuses:"SectionStatuses",
		updated_at:"String",
		is_expired:"Boolean",
		system_power:"Float",
		new_journey:"Boolean"
	},
	FinancingListTotalByStep:{
		simulation:"Int",
		formalization:"Int",
		installation:"Int",
		concluded:"Int",
		expired:"Int"
	},
	FinancingListTotalByStatus:{
		pending:"Int",
		expired:"Int",
		reproved:"Int",
		approved:"Int",
		under_analysis:"Int",
		pendency:"Int",
		send_contract:"Int",
		pending_customer:"Int",
		pending_receipt:"Int",
		concluded:"Int",
		canceled:"Int",
		kit_pending:"Int"
	},
	FinancingsList:{
		data:"Financing",
		total:"Int",
		total_by_step:"FinancingListTotalByStep",
		total_by_status:"FinancingListTotalByStatus"
	},
	FinancingType:{
		max_financing_value:"Float",
		min_financing_value:"Float",
		max_financing_installments:"Int",
		grace_period_financing:"GracePeriod",
		max_bnpl_value:"Float",
		max_bnpl_installments:"Int",
		grace_period_bnpl:"GracePeriod"
	},
	GracePeriod:{
		label:"String",
		value:"Int"
	},
	Query:{
		financing:"Financing",
		financings:"FinancingsList",
		financing_type:"FinancingType",
		installation:"Installation",
		legacyFinancing:"LegacyFinancing",
		partners:"CorePartner",
		project:"Project",
		getOpportunities:"GetOpportunitiesResponse",
		me:"User"
	},
	Status:{
		description:"String",
		id:"Int",
		slug:"DocumentStatusType"
	},
	Document:{
		filename:"String",
		filetype:"String",
		id:"String",
		status:"Status",
		type:"String",
		url:"String"
	},
	Section:{
		documents:"Document",
		id:"String",
		status:"Status",
		type:"TypeSection"
	},
	InstallationStatus:{
		description:"String",
		id:"Int",
		slug:"FileStatusType"
	},
	Installation:{
		comment:"String",
		concluded_at:"String",
		grade:"Int",
		has_ampera:"Boolean",
		has_ampera_activated:"Boolean",
		has_minimal_projects:"Boolean",
		id:"String",
		new_journey:"Boolean",
		plant_id:"String",
		plant_url:"String",
		project_id:"String",
		project_name:"String",
		sections:"Section",
		status:"InstallationStatus"
	},
	LegacyFinancing:{
		projectId:"String",
		financingId:"Int",
		formalizedAt:"String",
		stage:"String",
		status:"String"
	},
	CorePartner:{
		id:"Int",
		cnpj:"String",
		responsible:"CoreParterResponsible",
		company_name:"String",
		fantasy_name:"String",
		status:"CoreParterStatus"
	},
	CoreParterResponsible:{
		id:"Int",
		full_name:"String"
	},
	CoreParterStatus:{
		active:"Boolean",
		reason:"String",
		date:"String"
	},
	AppliedModifierOption:{
		type:"String",
		value:"String"
	},
	AppliedModifier:{
		name:"String",
		type:"String",
		options:"AppliedModifierOption"
	},
	AddonProduct:{
		id:"ID",
		product_name:"String",
		product_external_id:"String",
		simulation_id:"String",
		type:"String",
		product_slug:"String",
		applied:"Boolean",
		partner_commission:"Float",
		installment_price:"Float",
		total_price:"Float",
		monthly_interest_rate:"Float",
		engine_simulation_id:"String",
		previous_engine_simulation_id:"String",
		cet:"Float",
		applied_modifiers:"AppliedModifier"
	},
	SelectedInstallments:{
		cet:"String",
		iof:"Float",
		ipca:"String",
		addons:"AddonProduct",
		aggregated_addons:"AddonProduct",
		user_id:"Int",
		document:"String",
		is_combo:"Boolean",
		commission:"Float",
		partner_id:"Int",
		aliquot_iof:"Float",
		new_journey:"Boolean",
		person_type:"String",
		down_payment:"Float",
		grace_period:"Int",
		installments:"Int",
		project_name:"String",
		system_power:"Float",
		interest_rate:"Float",
		cet_percentage:"Float",
		commission_raw:"Float",
		financing_value:"Float",
		taxa_de_cadastro:"Float",
		installment_value:"Float",
		is_from_suggested:"Boolean",
		registration_fee_gross:"Float",
		registration_fee_raw_dry:"Float",
		registration_fee_raw_final:"Float"
	},
	RiskInfo:{
		title:"String",
		description:"String",
		todo:"String",
		reason:"String",
		decision:"Int"
	},
	BNPL:{
		decision_pre_analysis:"Int",
		min_entry_value:"Float",
		max_installments:"Int",
		pre_approved_value:"Float",
		status:"String"
	},
	Project:{
		id:"ID",
		user_id:"Int",
		partner_id:"Int",
		person_type:"String",
		document:"String",
		created_at:"String",
		representative_document:"String",
		project_value:"Float",
		system_power:"Float",
		grace_period:"Int",
		down_payment:"Float",
		status:"String",
		project_status:"String",
		max_installments:"Int",
		max_grace_period:"Int",
		project_name:"String",
		project_state:"String",
		partner_profile:"String",
		pre_approved_value:"Float",
		products_applieds:"String",
		installments:"String",
		risk_info:"RiskInfo",
		state_registration:"String",
		complete:"Boolean",
		selected_installments:"SelectedInstallments",
		birth_date:"String",
		decision_pre_analysis:"Int",
		score_sol_facil:"Int",
		simulation_id:"String",
		addons:"AddonProduct",
		max_approved_project_value:"Float",
		min_approved_project_value:"Float",
		max_financing_value:"Float",
		border_line:"Boolean",
		is_formalized:"Boolean",
		new_journey:"Boolean",
		formalized_at:"String",
		shopping_cart_id:"String",
		bnpl:"BNPL",
		bnpl_simulation_id:"String",
		bnpl_status:"String",
		is_onflex_partner:"Boolean",
		can_select_ampera:"Boolean",
		has_energy_bill_analysis:"Boolean",
		insurance_commission:"Float",
		gained_points:"Int",
		original_installment_value:"Float"
	},
	EnergyBillAnalysisDecision:{
		decision:"String",
		details:"String"
	},
	GetOpportunitiesResponse:{
		addons_opportunities:"AddonsOpportunities",
		financing_gained_points:"Int"
	},
	AddonsOpportunities:{
		addon_type:"String",
		gained_points:"Int",
		product_slug:"String"
	},
	LevelBlackStatus:{
		kwGoalMonth:"Int",
		kwReachedMonth:"Int"
	},
	Contact:{
		landline:"String",
		mobile:"String",
		email:"String"
	},
	Address:{
		street:"String",
		number:"String",
		complement:"String",
		reference:"String",
		district:"String",
		zipcode:"String",
		city:"String",
		state:"String"
	},
	Responsible:{
		id:"Int",
		username:"String",
		profile:"String",
		fullname:"String",
		document:"String",
		birthdate:"String",
		active:"Boolean",
		created_at:"String",
		contact:"Contact",
		partner:"Partner"
	},
	Permission:{
		store:"Boolean",
		financing:"Boolean"
	},
	Score:{
		current:"Int",
		goal:"Int"
	},
	Downgrade:{
		has_risk:"Boolean"
	},
	Sfplus:{
		level:"Int",
		score:"Score",
		black_status:"LevelBlackStatus",
		downgrade:"Downgrade",
		is_level_black:"Boolean",
		next_transition_date:"String"
	},
	Partner:{
		id:"Int",
		corporate_name:"String",
		operation_term_accepted:"Boolean",
		trade_name:"String",
		document:"String",
		category:"String",
		state_registration:"String",
		active:"Boolean",
		created_at:"String",
		address:"Address",
		responsible:"Responsible",
		permission:"Permission",
		users:"User",
		sfplus:"Sfplus"
	},
	Post:{
		id:"Int",
		title:"String",
		body:"String",
		userId:"String",
		comments:"Comment"
	},
	Comment:{
		id:"Int",
		name:"String",
		email:"String",
		body:"String"
	},
	User:{
		id:"Int",
		username:"String",
		profile:"String",
		fullname:"String",
		document:"String",
		birthdate:"String",
		active:"Boolean",
		created_at:"String",
		contact:"Contact",
		partner:"Partner",
		posts:"Post"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}