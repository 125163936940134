import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

interface UseLegacyFinancingConfig {
  skip: boolean
}

export default function useLegacyFinancing(projectId: string, config?: UseLegacyFinancingConfig) {
  const _config = { skip: false, ...config }
  const queryKey = queryKeys.LEGACY_FINANCING(projectId)

  const queryFn = async () => {
    const res = await financialBff('query')({
      legacyFinancing: [{ projectId }, {
        financingId: true,
        stage: true,
        status: true,
      }],
    })
    return res.legacyFinancing
  }

  return useQuery({
    queryKey,
    queryFn,
    enabled: !_config.skip,
  })
}
