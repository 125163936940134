<script setup lang="ts">
import type { CustomerDataStatus } from '~/utils/customer-register/CustomerRegister'
import Santander from '~/pages/customer/santander/index.vue'
import Solfacil from '~/pages/customer/solfacil/index.vue'
import InstallationService from '~/services/installation/Installation'
import SimulatorService from '~/services/simulator/Simulator'
import { useCustomer } from '~/store-v2/customer/useCustomer'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import { useFinancing } from '~/store/useFinancing'
import { ProviderEnum } from '~/utils/customer-register/CustomerRegister'
import { getErrorMessage } from '~/utils/errors'
import verify from '~/utils/verify'

const { track } = useMixpanel()

const providersOptions = {
  [ProviderEnum.SANTANDER]: Santander,
  [ProviderEnum.SOLFACIL]: Solfacil,
}

const router = useRouter()
const project_id = router.currentRoute.value.params.id as string
const { isLoading: isFinancingLoading } = useFinancing(project_id)
const {
  error,
  isError,
  isLoading,
} = useFinancingV2(ref(project_id))
const simulatorService = new SimulatorService(useApi('simulator'))
const projectPersonType = ref('PF')
const isOnflexPartner = ref(false)
const hasEnergyBillAnalysis = ref(true)
const loadingGetProject = ref(false)
const customerRegisterProvider = ref<ProviderEnum | null>(null)
const hardwareForm = ref()
const errorMessage = computed(() => getErrorMessage(error.value))

const customerParams = computed(() => ({
  id: project_id,
  projectPersonType: projectPersonType.value,
}))

const { data: financingForm, isLoading: isLoadingPageCustomer, refetch: refetchCustomerRegister, error: errorCustomerForm } = useCustomer(customerParams)

onMounted(async () => {
  triggerRefetch()
  trackEventMixpanel()

  await getProject()
  await fetchHardwareForm()
})

async function getProject() {
  try {
    loadingGetProject.value = true

    const { data } = await simulatorService.get_project(project_id)

    if (data) {
      const verifyOldJourney = verify.oldJourneyFinancingListRedirect(data.new_journey || false)
      if (verifyOldJourney)
        return
      projectPersonType.value = data?.person_type
      isOnflexPartner.value = data?.is_onflex_partner || false
      hasEnergyBillAnalysis.value = data?.has_energy_bill_analysis ?? false
    }
  }
  catch (error) {
    console.error('File [id].vue > Não foi possível carregar as informações do projeto. Por favor, tente novamente.', error)
  }
  finally {
    loadingGetProject.value = false
  }
}

async function triggerRefetch() {
  await refetchCustomerRegister()
}

async function fetchHardwareForm() {
  try {
    const installationService = new InstallationService(useApi('installation'))
    const hardwareFormResponse = (await installationService.get_hardware_model(project_id))

    hardwareForm.value = hardwareFormResponse
  }
  catch { }
}

function trackEventMixpanel() {
  track('customer_data_page_view', { trigger: 'Ver a página Dados do cliente' })
}

const showEmptyState = computed(() => {
  return (projectPersonType.value && projectPersonType.value !== 'PF')
    && router.currentRoute.value.fullPath.includes('customer')
    && !customerRegisterProvider.value
})

const loading = computed(() => isLoadingPageCustomer.value || loadingGetProject.value || isFinancingLoading || isLoading.value)

const mappingErrors = computed (() => ['NOT_FOUND', 'UNAUTHORIZED', 'UNKNOWN'].includes(errorMessage.value))

watch(() => financingForm.value, (formData) => {
  if (formData?.provider?.name)
    customerRegisterProvider.value = formData?.provider?.name as ProviderEnum
})
</script>

<template>
  <div v-if="loading" class="container">
    <div class="my-4 card-container flex flex-col justify-between w-full">
      <span class="loader-project" />
    </div>
  </div>
  <div v-else>
    <template v-if="isError && mappingErrors">
      <SharedErrorsCheckAccess :error-message="errorMessage" />
    </template>
    <template v-else>
      <div v-if="financingForm && projectPersonType && projectPersonType === 'PF' && customerRegisterProvider && !errorCustomerForm">
        <h1>
          <component
            :is="providersOptions[customerRegisterProvider]"
            :person-form="financingForm.person_form"
            :plant-address="financingForm.plant_address_form"
            :residential-address="financingForm.residential_address_form"
            :installation-document="financingForm.installation_document"
            :provider="customerRegisterProvider"
            :status="financingForm.status as CustomerDataStatus"
            :trigger-refetch="triggerRefetch"
            :is-onflex-partner="isOnflexPartner"
            :has-energy-bill-analysis="hasEnergyBillAnalysis"
            :hardware-form="{ response: hardwareForm, refetch: fetchHardwareForm }"
          />
        </h1>
        <ResumeWidget />
      </div>
      <div v-if="showEmptyState && !errorCustomerForm" class="container">
        <div class="bg-neutral-high-pure w-full rounded-lg p-10 flex items-center justify-center">
          <SolEmptyState
            id="emptyStateCustomer"
            :title="$t('emptyStateCustomer.title')"
            :subtitle="$t('emptyStateCustomer.subtitle')"
            variant="no-content"
          />
        </div>
      </div>
      <div v-if="!customerRegisterProvider || errorCustomerForm" class="container">
        <div class="bg-neutral-high-pure w-full rounded-lg p-10 flex items-center justify-center">
          <SolEmptyState
            id="emptyStateCustomerRegisterProvider"
            :title="$t('emptyStateRegister.title')"
            :subtitle="$t('emptyStateRegister.subtitle')"
            variant="no-content"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.container {
  @apply flex flex-col items-center px-4xs pb-6 mt-6;

  @screen md:system {
    @apply p-12 mt-0;
  }
}

.loader-project {
  @apply h-96 rounded-lg;
  @apply top-0 right-0 bottom-0 left-0 z-50;
  background: rgba(#fff, 0.9) url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat center center;
}
</style>

<route lang="yaml">
meta:
  layout: client-data
</route>
