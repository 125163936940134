<script setup lang="ts">
import type { User } from '~/utils/auth/User'
import type { EquipmentSend, HardwareBodyDraft, HardwareBodySave, HardwareCombos, HardwareCombosModel, HardwareCombosSelect, OptionsForm, PowerRange } from '~/utils/installation/Installation'
import { useDownload, useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { match, P } from 'ts-pattern'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'
import AddIcon from '~icons/material-symbols/add'
import DeleteIcon from '~icons/material-symbols/delete-outline'
import InstallationService from '~/services/installation/Installation'
import SimulatorService from '~/services/simulator/Simulator'
import { useStoreToolsFinancing } from '~/store/storeToolsFinancing'
import { useFinancing } from '~/store/useFinancing'
import filtersStoreMarketFinancing from '~/utils/filtersStoreMarketFinancing'
import { HardwareMirrorStatus } from '~/utils/installation/Installation'
import { getHardwareListLabels } from './utils'

const props = defineProps<{
  hardwareInformation: HardwareCombosModel
  refetchForm: () => Promise<void>
}>()
const { rest } = useConnections()
const shoppingCartId = useStoreToolsFinancing()
const { track } = useMixpanel()
const { downloadFile } = useDownload()

const projectHardwareCombos = ref({} as HardwareCombos)

const installationService = new InstallationService(useApi('installation'))

const simulatorService = new SimulatorService(useApi('simulator'))
const projectShoppingCartId: any = ref('')

const user: User = useAppStorage().get('user')
const CHANNEL = 'plugin-financiamento'
const ENV = useEnvironment()
const { t } = useI18n()
const router = useRouter()
const {
  createErrorToast,
  createSuccessToast,
  createInformativeToast,
} = useToast()
const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const { financing } = useFinancing(router.currentRoute.value.params.id as string)
const disableStepsAccordingStatus = ['canceled', 'expired'].includes(financing?.status ?? '')
const hasStoreOrder = props.hardwareInformation.has_store_order

enum KitOrigins {
  MIRROR = 'mirror',
  STORE = 'showcase',
}

const selectKitOrigin = [
  { name: KitOrigins.MIRROR, label: t('project_data.form.mirror'), value: KitOrigins.MIRROR },
  { name: KitOrigins.STORE, label: t('project_data.form.showcase'), value: KitOrigins.STORE },
]

const inverterSelectionFieldCount = ref()
const loadingProjectData = ref(false)
const showButtonNext = ref(false)
const invertersData = ref([] as EquipmentSend[])
const modulesData = ref([] as EquipmentSend[])
const powerRangesData = ref({} as PowerRange)
const showInvalidKitAlert = ref(false)
const kitDraft = ref<HardwareBodyDraft>()
const selectedKitOrigin = ref(props.hardwareInformation?.is_showcase ? KitOrigins.STORE : KitOrigins.MIRROR)
const isComboMirror = ref(selectedKitOrigin.value === KitOrigins.MIRROR)
const mirrorFile = ref<File[]>()
const mirrorUploadFail = ref<string>()
const loadingMirrorUpload = ref(false)
const showUploadMirror = props.hardwareInformation?.mirror_metadata === null || props.hardwareInformation?.mirror_metadata?.status === HardwareMirrorStatus.REPROVED
const id_project = String(router.currentRoute.value.params.id)
const loadingBackgroundInfo = ref(false)

watch(selectedKitOrigin, (value) => {
  isComboMirror.value = value === KitOrigins.MIRROR
  if (value === KitOrigins.MIRROR) {
    showInvalidKitAlert.value = false
  }
})

function setQuantityOfInvertersFields() {
  inverterSelectionFieldCount.value = props.hardwareInformation?.inverters?.length ?? 1
}

const radiosInverterType = [
  {
    name: 'inverterType',
    value: 'string',
    label: 'Normal (String)',
  },
  {
    name: 'inverterType',
    value: 'microinverter',
    label: 'Microinversor',
  },
]
const comboPrice = ref(0)

async function getPowerRanges() {
  try {
    const { data } = await installationService.get_power_range_hardware()
    if (data)
      powerRangesData.value = data
  }
  catch (error) {
    console.error(`Error getPowerRanges: ${error}`)
  }
}

const showStoreMarketFinancingLoading = ref(false)

async function getShoppingCartId() {
  try {
    showStoreMarketFinancingLoading.value = true
    const response = await simulatorService.get_simulation_by_shopping_cart_id(id_project)
    projectShoppingCartId.value = response.data
    shoppingCartId.setShoppingCartId(String(response.data))
  }
  catch (error) {
    projectShoppingCartId.value = ''
    shoppingCartId.setShoppingCartId('')
    console.error(`Error getShoppingCartId: ${error}`)
  }
  finally {
    showStoreMarketFinancingLoading.value = false
  }
}

async function updateShoppingCartId(projectId: string, cartId: string) {
  const payload = {
    shopping_cart_id: cartId,
  }

  try {
    await simulatorService.put_simulation_by_shopping_cart_id(projectId, payload)
  }
  catch (error) {
    console.error(error)
    createErrorToast('Não foi possível realizar a ação de atualizar o kit. Por favor, tente novamente.')
  }
}

async function postKitDraft(draft: HardwareBodyDraft) {
  try {
    if (!draft)
      return

    await installationService.post_hardware_draft(draft)
  }
  catch {
    createErrorToast(t('project_data.errors.failed_to_save_kit_draft'))
  }
}

const optionsSelect = ref({} as HardwareCombosSelect)

async function getCombosHardware() {
  try {
    const { data } = await installationService.get_combos_hardware(id_project)
    if (data) {
      projectHardwareCombos.value = data

      for (const key in data) {
        if (data[key]) {
          optionsSelect.value[key] = data[key].map((item: OptionsForm) => ({
            name: getHardwareListLabels(item, key),
            value: Number(item.id),
          }))
        }
      }
    }
  }
  catch (error: any) {
    return createErrorToast(error?.data?.detail ? error.data.detail : 'Não foi possível carregar as informações das seleções do formulário. Por favor, tente novamente.')
  }
}

const project: any = ref({})
const loadingGetProject = ref(false)

async function getProject() {
  try {
    loadingGetProject.value = true
    const { data } = await simulatorService.get_project(router.currentRoute.value.params.id)
    project.value = data
  }
  catch (error) {
    console.error(error)
    createErrorToast('Não foi possível carregar as informações do projeto. Por favor, tente novamente.')
  }
  finally {
    loadingGetProject.value = false
  }
}

const isSelectedKitOut = ref(false)
const inverterTypeSelect = ref('')

const isMicroinversor = ref((props.hardwareInformation?.microinverters?.length ?? 0) > 0)

const validationSchema = computed(() => toTypedSchema(
  zod.object({
    distributor: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    installationPhase: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    inverterType: !props.hardwareInformation.is_combo || isComboMirror.value
      ? zod.enum(['string', 'microinverter'])
      : zod.string().optional(),
    moduleQuantity: !props.hardwareInformation.is_combo || isComboMirror.value
      ? zod.union([zod.string()
        .refine(value => /^[1-9]\d*$/.test(value), t('form.required')), zod.number().min(1, t('form.required'))])
      : zod.any().optional(),
    microinversorQuantity: inverterTypeSelect.value === 'microinverter' && selectedKitOrigin.value !== KitOrigins.STORE
      ? zod.union([zod.string()
        .refine(value => /^[1-9]\d*$/.test(value), t('form.required')), zod.number().min(1, t('form.required'))])
      : zod.any().optional(),
    inverters: (inverterTypeSelect.value === 'microinverter') || ((props.hardwareInformation.is_combo && selectedKitOrigin.value === KitOrigins.STORE))
      ? zod.any().optional()
      : zod.array(zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      }, { required_error: t('form.required') })),
    microinversors: inverterTypeSelect.value === 'microinverter' && selectedKitOrigin.value !== KitOrigins.STORE
      ? zod.array(zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      }))
      : zod.any().optional(),
    modules: !props.hardwareInformation.is_combo || isComboMirror.value
      ? zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      })
      : zod.any().optional(),
  }),
))

const { values, validate, setFieldValue, errors, setErrors } = useForm({
  validationSchema,
  initialValues: {
    distributor: {
      name: props?.hardwareInformation?.distributor?.slug || props?.hardwareInformation?.distributor?.name || 'Selecione',
      value: props?.hardwareInformation?.distributor?.id || 0,
    },
    installationPhase: {
      name: props?.hardwareInformation?.phase?.name || 'Selecione',
      value: props?.hardwareInformation?.phase?.id || 0,
    },
    inverterType: String(props?.hardwareInformation?.inverter_type),
    inverters: props?.hardwareInformation?.inverters !== null && props?.hardwareInformation?.inverters?.length > 0
      ? props.hardwareInformation.inverters.map((item) => { return { name: getHardwareListLabels(item as unknown as OptionsForm, 'inverters'), value: item.id } })
      : props.hardwareInformation?.inverters === null ? null : [],
    moduleQuantity: props.hardwareInformation.modules !== null && props.hardwareInformation?.modules?.length > 0 ? props.hardwareInformation.modules[0].quantity : '',
    microinversors: [{
      name: isMicroinversor.value ? getHardwareListLabels(props.hardwareInformation?.microinverters?.[0] as unknown as OptionsForm, 'microinverters') : 'Selecione',
      value: isMicroinversor.value ? props.hardwareInformation?.microinverters?.[0]?.id : 0,
    }],
    modules: props?.hardwareInformation?.modules !== null && props?.hardwareInformation?.modules?.length > 0
      ? props.hardwareInformation?.modules?.map((item) => { return { name: getHardwareListLabels(item as unknown as OptionsForm, 'modules'), value: item.id } })?.reduce((acc, cur) => Object.assign(acc, cur))
      : { name: 'Selecione', value: 0 },
    microinversorQuantity: props?.hardwareInformation?.microinverters && (props?.hardwareInformation?.microinverters?.length ?? 0) > 0
      ? Number(props?.hardwareInformation?.microinverters?.map((item) => { return item.quantity })?.reduce((acc, cur) => Object.assign(acc, cur)))
      : '',
  },
})

watch(values, (newValue) => {
  inverterTypeSelect.value = newValue.inverterType ?? ''
})

function showErrorStringInverter(isUnderExpected: boolean) {
  if (isUnderExpected) {
    values.inverters?.forEach((_, index) => {
      setErrors({
        ...errors.value,
        [`inverters[${index}]`]: t('project_data.errors.string_overload_min'),
      })
    })

    return
  }

  values.inverters?.forEach((_, index) => {
    setErrors({
      ...errors.value,
      [`inverters[${index}]`]: t('project_data.errors.string_overload_max'),
    })
  })
}

function showErrorMicroinverter(isUnderExpected: boolean) {
  if (isUnderExpected) {
    values.microinversors?.forEach((_, index) => {
      setErrors({
        ...errors.value,
        microinversorQuantity: ' ',
        [`microinversors[${index}]`]: t('project_data.errors.microinverter_overload_min'),
      })
    })

    return
  }

  values.microinversors?.forEach((_, index) => {
    setErrors({
      ...errors.value,
      microinversorQuantity: ' ',
      [`microinversors[${index}]`]: t('project_data.errors.microinverter_overload_max'),
    })
  })
}

function showPricePerWattError() {
  setErrors({
    ...errors.value,
    moduleQuantity: ' ',
    modules: t('project_data.errors.modules_price_per_watt'),
  })
}

async function validateHardwareForm(form: HardwareBodySave) {
  loadingBackgroundInfo.value = true

  const validation = await installationService.validate_kit(form)
  loadingBackgroundInfo.value = false

  if (validation.isValid) {
    setErrors({})
  }
  else {
    if (validation.hardware.modules.pricePerWatt.isValid === false) {
      showPricePerWattError()
    }

    if (validation.hardware.inverters.overload.isValid === false) {
      const { isUnderExpected } = validation.hardware.inverters.overload

      if (values.inverterType === 'string') {
        showErrorStringInverter(isUnderExpected)
      }
      else {
        showErrorMicroinverter(isUnderExpected)
      }
    }
  }

  return validation
}

async function validateDraft(draft: HardwareBodyDraft) {
  loadingBackgroundInfo.value = true

  if (!draft) {
    return false
  }

  const body = getHardwareFormPayload()
  try {
    const { isValid } = await installationService.validate_kit(body)
    return isValid
  }
  catch {
    loadingBackgroundInfo.value = false
  }
  finally {
    loadingBackgroundInfo.value = false
  }
}

function addInverter() {
  inverterSelectionFieldCount.value = inverterSelectionFieldCount.value + 1
  track('formalizing_equipments_button_add-inverter', { trigger: 'Clique no botão de adicionar inversor' })
}

function removeInverter(index: number) {
  const valueToRemove = values.inverters[index]
  const inverterToRemove = values.inverters.filter(item => item !== valueToRemove)
  setFieldValue('inverters', inverterToRemove)
  inverterSelectionFieldCount.value = values?.inverters?.length
}

function resetField() {
  inverterSelectionFieldCount.value = 1
  if (props.hardwareInformation?.distributor !== null && props.hardwareInformation?.distributor?.id === 0)
    setFieldValue('distributor', { name: 'Selecione', value: 0 })

  if (props.hardwareInformation?.phase !== null && props.hardwareInformation.phase.id === 0)
    setFieldValue('installationPhase', { name: 'Selecione', value: 0 })

  setFieldValue('moduleQuantity', '')
  setFieldValue('modules', { name: 'Selecione', value: 0 })

  if (values.inverterType === 'microinverter') {
    isMicroinversor.value = true
    setFieldValue('microinversors', [{ name: 'Selecione', value: 0 }])
  }

  else {
    isMicroinversor.value = false
    setFieldValue('inverters', null)
  }
}
const addKit = ref(false)

watch(projectShoppingCartId, (newValue) => {
  if (newValue)
    projectShoppingCartId.value = newValue
})

function getHardwareFormPayload() {
  // eslint-disable-next-line style/max-statements-per-line
  const invertersPayload = isMicroinversor.value ? values.microinversors?.map((item: { name: string, value: number }) => { return { name: item.name, id: item.value, quantity: values.microinversorQuantity } }) : values.inverters?.map((item: { name: string, value: number }) => { return { name: item.name, id: item.value, quantity: 1 } })
  const isShowcase = selectedKitOrigin.value === KitOrigins.STORE

  const hardwareDataForm: HardwareBodySave = {
    is_combo: props.hardwareInformation.is_combo,
    is_showcase: isShowcase,
    project_id: id_project,
    distributor_id: values?.distributor?.value !== undefined ? values?.distributor?.value : 0,
    phase_id: values?.installationPhase?.value !== undefined ? values?.installationPhase?.value : 0,
    inverters: invertersPayload,
    modules: [{ id: values.modules?.value ?? '', quantity: values.moduleQuantity }],
    combo_metadata: props.hardwareInformation.is_combo ? kitDraft.value : null,
  }
  if (props.hardwareInformation.is_combo && isShowcase && addKit.value) {
    hardwareDataForm.id = projectShoppingCartId.value
    hardwareDataForm.combo_price = comboPrice.value
    hardwareDataForm.inverters = invertersData.value
    hardwareDataForm.modules = modulesData.value
    hardwareDataForm.shopping_cart_id = projectShoppingCartId.value
  }

  if (props.hardwareInformation.is_combo && isShowcase && !addKit.value) {
    delete hardwareDataForm.id
    delete hardwareDataForm.combo_price
    delete hardwareDataForm.inverters
    delete hardwareDataForm.modules
    delete hardwareDataForm.shopping_cart_id
  }

  if (!props.hardwareInformation.is_combo || !isShowcase)
    hardwareDataForm.inverter_type = values.inverterType

  return hardwareDataForm
}

async function saveProject() {
  const { valid } = await validate()

  if (valid) {
    try {
      loadingProjectData.value = true

      const hardwareDataForm = getHardwareFormPayload()

      await installationService.post_save_hardware(hardwareDataForm)
      await props.refetchForm()

      createSuccessToast('Equipamentos salvos com sucesso')
      track('formalizing_equipments_button_save', { trigger: 'Clique no botão de salvar na seção de equipamentos', ...hardwareDataForm })

      showButtonNext.value = true
    }
    catch (error: any) {
      loadingProjectData.value = false
      return createErrorToast(error?.data?.detail ? error.data.detail : 'Erro ao salvar os equipamentos. Tente novamente mais tarde.')
    }
    finally {
      loadingProjectData.value = false
    }
  }
  else {
    createErrorToast(t('form.alert_required_fields'))
  }
}

function transformInverters(inverters: any[]): EquipmentSend[] {
  const invertersFormatted = inverters.map(inverter => ({
    quantity: Number(inverter.amount) || Number(inverter.quantity),
    power: (inverter.power)?.toString(), // Potência
    id: Number.parseInt(inverter.sku),
  }))
  return invertersFormatted
}

function transformModules(power: number, modules: any[]): EquipmentSend[] {
  const modulesFormatted = modules.map(module => ({
    quantity: Number(module.amount) || Number(module.quantity),
    power: ((power / (Number(module.amount) || Number(module.quantity))) * 1000)?.toString(), // (Potência do Kit / Quantidade de módulos * 1000) = Potência por módulo kWp
    id: Number.parseInt(module.sku),
  }))
  return modulesFormatted
}

function transformKitToDraft(kit): HardwareBodyDraft {
  const inverters = kit.inverters.map((inverter) => {
    return {
      quantity: inverter.amount,
      power: inverter.power,
      sku: inverter.sku,
      brand: inverter.brand,
      model: inverter.model,
    }
  })
  const modules = kit.modules.map((module) => {
    return {
      quantity: module.amount,
      power: (kit.power * 1000) / module.amount,
      sku: module.sku,
      brand: module.brand,
      model: module.model,
    }
  })

  return {
    project_id: router.currentRoute.value.params.id as string,
    inverters,
    modules,
    price: kit.price,
    sku: kit.sku,
    power: kit.power,
  }
}

function setHardwareDataFromDraft(draft: HardwareBodyDraft) {
  if (draft.power && draft.modules) {
    modulesData.value = transformModules(draft.power, draft.modules)
  }

  invertersData.value = draft.inverters ? transformInverters(draft.inverters) : []
  comboPrice.value = draft.price ? draft.price : 0
  addKit.value = true
}

function setHardwareFromDraft(draft: HardwareBodyDraft) {
  if (draft.power && draft.modules) {
    modulesData.value = transformModules(draft.power, draft.modules)
  }

  invertersData.value = draft.inverters ? transformInverters(draft.inverters) : []
  comboPrice.value = draft.price ? draft.price : 0
  kitDraft.value = draft
}

async function onAddKit(data: { cartId: any, kit: any }) {
  addKit.value = true
  projectShoppingCartId.value = String(data.cartId)
  updateShoppingCartId(id_project, projectShoppingCartId.value)
  createInformativeToast({
    title: t('toastKitSelected.title'),
    description: t('toastKitSelected.description'),
  })

  const _draft = transformKitToDraft(data.kit)

  setHardwareFromDraft(_draft)

  await postKitDraft(_draft)

  isSelectedKitOut.value = false

  const isValid = await validateDraft(_draft)

  showInvalidKitAlert.value = !isValid
  showButtonNext.value = isValid ?? false
}

async function onDeleteKit() {
  addKit.value = false
  projectShoppingCartId.value = ''
  showInvalidKitAlert.value = false
  showButtonNext.value = false

  const payload = {
    project_id: id_project,
  }

  await postKitDraft(payload)
}

async function onLoadCart(result) {
  if (result?.items?.length > 0) {
    addKit.value = true

    const hasUnavailableKitItem = result.items.some(item => item.status.description === 'unavailable')
    const isValid = await validateDraft(kitDraft.value!)

    isSelectedKitOut.value = hasUnavailableKitItem
    showButtonNext.value = !hasUnavailableKitItem && (isValid ?? false)
  }
}

async function submitMirror() {
  try {
    loadingMirrorUpload.value = true
    if (mirrorFile.value === undefined)
      return

    mirrorUploadFail.value = undefined
    await installationService.post_mirror({
      projectId: router.currentRoute.value.params.id as string,
      mirror: mirrorFile.value[0],
    })

    await props.refetchForm()

    createInformativeToast({
      description: t('project_data.notifications.mirror_uploaded'),
    })

    pollingMirrorStatus()
  }
  catch (error) {
    console.error(error)
    mirrorUploadFail.value = t('project_data.notifications.mirror_upload_error')

    createErrorToast({
      description: t('project_data.notifications.mirror_upload_error'),
    })
  }
  finally {
    loadingMirrorUpload.value = false
  }
}

function handleMirrorDownload() {
  if (props.hardwareInformation?.mirror_metadata?.document_url)
    downloadFile(props.hardwareInformation.mirror_metadata.document_url)
}

function handleRemoveMirror() {
  mirrorFile.value = undefined
}

async function submitTechnicalData() {
  const { valid } = await validate()

  if (!valid) {
    return
  }

  const hardwareDataForm = getHardwareFormPayload()

  const { isValid } = await validateHardwareForm(hardwareDataForm)

  if (isValid)
    await saveProject()
}

async function pollingMirrorStatus() {
  const interval = setInterval(async () => {
    const { data } = await installationService.get_mirror_status(router.currentRoute.value.params.id as string)

    if (data.status === HardwareMirrorStatus.APPROVED) {
      clearInterval(interval)

      await props.refetchForm()
    }
  }, 15000) // 15 seconds
}

type FormState = 'non_combo' | 'combo' | 'mirror_submitted' | 'mirror_upload'

const formStateShown = computed(() => {
  return match([props.hardwareInformation, financing])
    .with([{ is_combo: false }, P._], () => 'non_combo')
    .with([{ is_combo: true, is_showcase: false, mirror_metadata: { status: HardwareMirrorStatus.APPROVED } }, P._], () => 'mirror_submitted')
    .with([{ is_combo: true, is_showcase: false }, { section_statuses: { formalization: P.optional({ hardware: 'kit_pending' }) } }], () => 'mirror_upload')
    .with([{ is_combo: true, is_showcase: P.union(true, false, null) }, P._], () => 'combo')
    .otherwise(() => 'non_combo') as FormState
})

watch(mirrorFile, async (newValue) => {
  if (newValue)
    await submitMirror()
})

function setKitOrigin() {
  if (props.hardwareInformation?.is_showcase === null && projectShoppingCartId.value)
    selectedKitOrigin.value = KitOrigins.STORE

  if (selectedKitOrigin.value === KitOrigins.MIRROR || props.hardwareInformation?.mirror_metadata !== null) {
    showInvalidKitAlert.value = false
  }
}

function getDocumentInfoByStatus() {
  return {
    'file-type-icon': ['pdf'],
    'file-name': ['Espelho-do-pedido.pdf'],
    'status-type-document': [props.hardwareInformation?.mirror_metadata?.status],
  }
}

onMounted(async () => {
  setQuantityOfInvertersFields()
  if (props.hardwareInformation.is_combo)
    await getShoppingCartId()
  await getProject()
  await getCombosHardware()
  await getPowerRanges()
  setKitOrigin()

  if (props.hardwareInformation.is_combo && props.hardwareInformation.combo_metadata) {
    addKit.value = true

    setHardwareFromDraft(props.hardwareInformation.combo_metadata)

    const isValid = await validateDraft(props.hardwareInformation.combo_metadata)
    showInvalidKitAlert.value = props.hardwareInformation?.mirror_metadata !== null ? false : !isValid
    kitDraft.value = props.hardwareInformation.combo_metadata
    setHardwareDataFromDraft(props.hardwareInformation.combo_metadata)
  }

  if (props.hardwareInformation?.mirror_metadata?.status === HardwareMirrorStatus.REPROVED)
    mirrorUploadFail.value = t('project_data.notifications.mirror_uploaded_reproved')

  if (props.hardwareInformation?.mirror_metadata?.status === HardwareMirrorStatus.PENDING)
    pollingMirrorStatus()
})

const showAlertErrorHardware = computed(() => {
  const isReproved = props.hardwareInformation?.mirror_metadata?.status === HardwareMirrorStatus.REPROVED
  const hasReason = Boolean(props.hardwareInformation?.mirror_metadata?.reason)
  return isReproved && hasReason
})

function disabledFields(value: boolean) {
  return disableStepsAccordingStatus || value
}

const disabledSubmitTechnicalData = computed(() => {
  return disableStepsAccordingStatus || (values?.distributor?.name === null || values?.installationPhase?.name === null)
})

const showUploadedFile = props.hardwareInformation.mirror_metadata?.document_url && (props.hardwareInformation.mirror_metadata?.status !== HardwareMirrorStatus.REPROVED && props.hardwareInformation.mirror_metadata?.status !== HardwareMirrorStatus.PENDING)

function openSolfacilStore() {
  const urlSolfacilStore = 'https://loja.solfacil.com.br/'

  return window.open(urlSolfacilStore, '_blank')
}
</script>

<template>
  <div class="form-project-data">
    <SolAlert
      v-if="isSelectedKitOut"
      id="alert-kit-out"
      class="mb-2"
      feedback="warning"
      :title="t('project_data.alerts.kit_out.title')"
      :text="t('project_data.alerts.kit_out.text')"
    />
    <SolAlert
      v-if="showInvalidKitAlert"
      id="alert-invalid-kit"
      class="mb-2"
      feedback="warning"
      :title="t('project_data.alerts.invalid_kit.title')"
      :text="t('project_data.alerts.invalid_kit.text')"
    />

    <SolAlert
      v-if="showAlertErrorHardware"
      id="informative-feedback-receipt-model"
      class="my-3"
      feedback="error"
    >
      {{ props.hardwareInformation?.mirror_metadata?.reason }}
    </SolAlert>

    <form id="hardware-form">
      <div class="grid grid-cols-1 md:system:grid-cols-2 gap-6 mt-6 mb-6">
        <SolSelect
          id="energy-concessionaire-field"
          name="distributor"
          :label="`${t('form.energy_concessionaire')}`"
          :use-field="useField"
          :options="optionsSelect.distributor"
          :searchable="true"
          :disabled="disabledFields(Boolean(props.hardwareInformation.distributor)) || hasStoreOrder"
        />
        <SolSelect
          id="installation-phase-field"
          name="installationPhase"
          :label="`${t('form.installation_phase')}`"
          :use-field="useField"
          :options="optionsSelect.phase"
          :disabled="disabledFields(Boolean(props.hardwareInformation.phase)) || hasStoreOrder"
        />
      </div>

      <div v-if="formStateShown === 'mirror_upload' || formStateShown === 'mirror_submitted'">
        <h4 class="fonts-heading-h4 mb-4">
          {{ t('project_data.heading.kit') }}
        </h4>

        <template v-if="formStateShown === 'mirror_upload'">
          <SolAlert
            id="mirror-store-alert"
            class="mb-2"
            feedback="informative"
            :text="t('project_data.alerts.mirror.info_payment_method_store')"
            :action="t('project_data.alerts.mirror.info_payment_method_store_button')"
            @click="openSolfacilStore()"
          />

          <template v-if="showUploadMirror">
            <SolFileUpload
              id="mirror-upload"
              v-model="mirrorFile"
              name="mirror-upload"
              :multiple="false"
              :file-name="mirrorFile?.[0]?.name"
              :label="t('project_data.form.mirror_upload')"
              :error="mirrorUploadFail"
              :loading="loadingMirrorUpload"
              :download-menu="['download', 'delete']"
              :class="{ 'pointer-events-none opacity-60': disableStepsAccordingStatus }"
              :disabled="hasStoreOrder"
              @menu:download="handleMirrorDownload"
              @menu:delete="handleRemoveMirror"
            />
          </template>
          <template v-else>
            <div>
              <span class="font-bold text-3xs text-neutral-low-dark">{{ t('project_data.form.mirror_upload') }}</span>
              <SharedMessageStatus
                v-bind="getDocumentInfoByStatus()"
              />
            </div>
          </template>
        </template>
        <UploadedFile
          v-if="showUploadedFile"
          :class="{ 'pointer-events-none opacity-60': disableStepsAccordingStatus }"
          :text="t('project_data.alerts.mirror_uploaded.text')"
          :action="{ text: t('project_data.alerts.mirror_uploaded.action_text') }"
          :download="{ link: props.hardwareInformation?.mirror_metadata?.document_url || '' }"
        />
      </div>

      <div
        v-if="formStateShown === 'combo'"
        :class="{ 'pointer-events-none opacity-60': disableStepsAccordingStatus }"
        class="bg-neutral-high-medium bg-opacity-intense p-4 mb-4 rounded-md"
      >
        <label for="kit-origin" class="fonts-body-medium-bold text-neutral-low-dark">
          {{ t('project_data.form.combo_option.label') }}
        </label>
        <SolRadioGroup
          id="kit-origin"
          v-model="selectedKitOrigin"
          class="mb-2"
          title=""
          name="mirror"
          :direction="isLargeScreen ? 'row' : 'column'"
          :radios="selectKitOrigin"
          :disabled="disableStepsAccordingStatus || hasStoreOrder"
        />
        <p class="fonts-subtitle-small px-4">
          {{ t('project_data.form.combo_option.subtitle') }}
        </p>
      </div>
      <!-- Com combo fácil -->
      <template v-if="formStateShown === 'combo' && selectedKitOrigin === KitOrigins.STORE">
        <StoreMarketFinancing
          v-if="!showStoreMarketFinancingLoading && !loadingGetProject"
          class="!px-0"
          :class="{ 'pointer-events-none': disableStepsAccordingStatus || project.is_formalized || hasStoreOrder }"
          :disable-filters="disableStepsAccordingStatus || project.is_formalized || hasStoreOrder"
          :disable-cart="disableStepsAccordingStatus || project.is_formalized || hasStoreOrder"
          title="Kits disponíveis no Combo Fácil"
          subtitle="Sugestões da nossa loja de acordo com as dimensões do seu projeto."
          :filters="filtersStoreMarketFinancing.createObject(`${(project.system_power)}`, project.project_state)"
          :channel="CHANNEL"
          :environment="ENV"
          :segmentation-id="id_project"
          :partner-id="user?.parceiro?.id"
          :cart-id="projectShoppingCartId"
          mix-panel-env="formalizing-equipments"
          :mix-panel-key="rest.mixpanel.key"
          @on-add-kit="onAddKit"
          @on-delete-kit="onDeleteKit"
          @on-load-cart="onLoadCart"
        />

        <div v-if="showButtonNext">
          <SolDivider thickness="x-small" orientation="horizontal" class="mt-8 mb-6" />

          <div class="flex justify-end">
            <SolButton
              id="address_confirm"
              size="large"
              :disabled="disabledSubmitTechnicalData || hasStoreOrder"
              :loading="loadingBackgroundInfo"
              @click="submitTechnicalData"
            >
              {{ t('app.next') }}
            </SolButton>
          </div>
        </div>
      </template>

      <!-- Sem combo fácil -->
      <template v-if="(formStateShown === 'combo' && selectedKitOrigin === KitOrigins.MIRROR) || formStateShown === 'non_combo'">
        <label
          :class="{ 'pointer-events-none opacity-60': disableStepsAccordingStatus }"
          for="inverter-type"
          class="fonts-body-medium-bold text-neutral-low-dark"
        >
          {{ t('project_data.form.inverter_type') }}
        </label>
        <div class="grid grid-cols-1 md:system:grid-cols-2 gap-6">
          <SolRadioGroup
            id="inverter-type"
            class="mb-2"
            title=""
            name="inverterType"
            :use-field="useField"
            direction="row"
            :radios="radiosInverterType"
            :disabled="disableStepsAccordingStatus || hasStoreOrder"
            @change="resetField()"
          />
        </div>
        <!-- Exibe os campos de acordo com o microinverter -->
        <template v-if="values.inverterType === 'microinverter'">
          <div class="grid grid-cols-1 md:system:grid-cols-4 gap-6 mt-6">
            <SolInputText
              id="microinversor-quantity-field"
              name="microinversorQuantity"
              type="number"
              :use-field="useField"
              :label="`${t('form.microinversor_quantity')}`"
              :disabled="disableStepsAccordingStatus || hasStoreOrder"
            />
            <div class="md:system:col-span-3">
              <SolSelect
                v-for="(_, index) in values.microinversors"
                id="microinversor-field"
                :key="index"
                class="w-full"
                :name="`microinversors[${index}]`"
                :label="`${t('form.microinversors')}`"
                :use-field="useField"
                :options="optionsSelect.microinverters"
                :searchable="true"
                :disabled="disableStepsAccordingStatus || hasStoreOrder"
              />
            </div>
          </div>
        </template>
        <!-- Exibe os campos de acordo com o Normal (String) -->
        <template v-else>
          <div class="grid grid-cols-1 gap-6 mt-6">
            <div
              v-for="(_, index) in inverterSelectionFieldCount"
              :key="index"
              class="flex items-end gap-2"
            >
              <SolSelect
                :id="`inverter-field-${index}`"
                class="w-full"
                :name="`inverters[${index}]`"
                :label="`${t('form.inverter')}`"
                :use-field="useField"
                :options="optionsSelect.inverters"
                :searchable="true"
                :disabled="disableStepsAccordingStatus || hasStoreOrder"
              />
              <template v-if="index > 0">
                <SolButton
                  id="removeInverter"
                  size="large"
                  variant="tertiary"
                  class="!h-[48px]"
                  :disabled="disableStepsAccordingStatus || hasStoreOrder"
                  @click="removeInverter(index)"
                >
                  <template #icon:left>
                    <DeleteIcon />
                  </template>
                </SolButton>
              </template>
            </div>

            <SolButton
              id="addInverter"
              size="large"
              variant="tertiary"
              :disabled="values?.inverters?.some((item) => item === undefined) || disableStepsAccordingStatus || hasStoreOrder"
              @click="addInverter()"
            >
              <template #icon:left>
                <AddIcon />
              </template>
              {{ t('form.button.add_inverter') }}
            </SolButton>
          </div>
        </template>

        <div class="grid grid-cols-1 md:system:grid-cols-4 gap-6 mt-6">
          <SolInputText
            id="module-quantity-field"
            name="moduleQuantity"
            type="number"
            :use-field="useField"
            :label="`${t('form.module_quantity')}`"
            :disabled="disableStepsAccordingStatus || hasStoreOrder"
          />
          <div class="md:system:col-span-3">
            <SolSelect
              id="module-field"
              class="w-full"
              name="modules"
              :label="`${t('form.modules')}`"
              :use-field="useField"
              :options="optionsSelect.modules"
              :searchable="true"
              :disabled="disableStepsAccordingStatus || hasStoreOrder"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 gap-6 mt-6">
          <SolDivider thickness="x-small" orientation="horizontal" />
        </div>
        <div class="grid grid-cols-1 md:system:grid-cols-4 gap-6 mt-6">
          <div class="md:system:col-start-4 flex justify-end">
            <SolButton
              id="add-equipment-button"
              size="large"
              class="w-full md:system:w-auto"
              :loading="loadingProjectData || loadingBackgroundInfo"
              :disabled="disableStepsAccordingStatus || Object.keys(errors).length > 0 || hasStoreOrder"
              @click="submitTechnicalData"
            >
              {{ t('app.save') }}
            </SolButton>
          </div>
        </div>
      </template>
    </form>
  </div>
</template>

<style lang="scss" scoped>
#hardware-form {
  :deep(.sol-alert-core.-informative) {
    @apply block mb-2xs;

    @screen md:system {
      @apply flex;
    }

    .sol-button-core.-secondary {
      @apply ml-sm mt-micro min-w-max;

      @screen md:system {
        @apply ml-0 mt-0;
      }
    }
  }
}

.form-project-data{
  @apply mt-sm mb-0;
}
</style>
