import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financingBff from '~/services-v2/financial-bff/financial-bff'

export default function useFinancingTypes() {
  const queryKey = ref(queryKeys.FINANCING_TYPES)

  const queryFn = async () => {
    const res = await financingBff('query')({
      __alias: {
        pf: {
          financing_type: [{
            person_type: 'PF',
          }, {
            max_financing_value: true,
            min_financing_value: true,
            max_financing_installments: true,
            grace_period_financing: {
              label: true,
              value: true,
            },
            max_bnpl_value: true,
            max_bnpl_installments: true,
            grace_period_bnpl: {
              label: true,
              value: true,
            },
          }],
        },
        pj: {
          financing_type: [{
            person_type: 'PJ',
          }, {
            max_financing_value: true,
            min_financing_value: true,
            max_financing_installments: true,
            grace_period_financing: {
              label: true,
              value: true,
            },
            max_bnpl_value: true,
            max_bnpl_installments: true,
            grace_period_bnpl: {
              label: true,
              value: true,
            },
          }],
        },
      },

    })

    return res
  }

  return useQuery({
    queryKey,
    queryFn,
    staleTime: 1000 * 30,
    retry: 3,
    retryDelay: 1000,
  })
}
