<script setup lang="ts">
import useOpportunities from '~/store-v2/opportunities/useOpportunities'

interface Addon {
  product_slug: string
  applied: boolean
  type?: string
}

interface Props {
  projectValue: number
  downPayment: number
  partnerId: number
  personType: string
  comissionRaw?: number
  insuranceCommission?: number
  gainedPoints?: number
  selectedAddons?: Addon[]
}

const props = defineProps<Props>()

const { formatMoney } = useMoney()
const { t } = useI18n()

const { data: opportunity, isLoading, isError } = useOpportunities({ financing_value: props.projectValue - props.downPayment, partner_id: props.partnerId, project_type: props.personType })

function formattedTextTooltip() {
  return t('resumeWidget.opportunities.title')
}

const gainedPointsWithAddons = computed(() => {
  let gainedPointsWithAddonsAcc = opportunity.value?.financing_gained_points || 0
  opportunity.value?.addons_opportunities.forEach((addon) => {
    if (addon.product_slug === null && props.selectedAddons?.find(item => item.type === addon.addon_type)) {
      gainedPointsWithAddonsAcc += addon?.gained_points ?? 0
    }
    if (props.selectedAddons?.find(item => item.product_slug === addon.product_slug)) {
      gainedPointsWithAddonsAcc += addon?.gained_points ?? 0
    }
  })

  return gainedPointsWithAddonsAcc
})

const commissions = computed(() => (props.comissionRaw || 0) + (props?.insuranceCommission || 0))
</script>

<template>
  <div v-if="isLoading || isError" />
  <div v-else>
    <span
      class="fonts-body-large-bold flex justify-between"
    >
      {{ $t('resumeWidget.opportunities.title') }}
      <SimulationTooltip
        position="left"
        :text="formattedTextTooltip()"
      >
        <SimulationSignalBar :signal="{ strength: gainedPoints || 0 }" />
      </SimulationTooltip>
    </span>
    <div class="container-details">
      <li v-if="commissions" class="details">
        <span class="text-neutral-low-light"> {{ $t('resumeWidget.opportunities.commission') }}</span>

        <span class="text-brand-secondary-pure font-bold">
          + {{
            formatMoney(commissions)
          }}
        </span>
      </li>

      <ul class="flex flex-col">
        <li class="details">
          <span class="text-neutral-low-light">{{ $t('solfacilPoints.label') }}</span>
          <span class="text-brand-secondary-pure font-bold">
            {{ gainedPointsWithAddons?.toLocaleString('pt-BR') }} pts
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
