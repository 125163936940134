import ky from 'axios'

import env from '~/env'
import {
  injectAccessTokenInterceptor,
  refreshTokenResponseInterceptor,
} from '~/services-v2/keycloack-api/keycloack-api.interceptors'

const http = ky.create({
  baseURL: env.VITE_PERSON_API_URL,
})
http.interceptors.request.use(injectAccessTokenInterceptor)
http.interceptors.response.use(c => c, refreshTokenResponseInterceptor)

export default http
