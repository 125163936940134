<script setup lang="ts">
import type { BiometryResponse, Tags } from '~/utils/customer-register/Biometry'
import { SolRadioGroup } from '@solfacil/girassol'
import IconCheck from '~icons/material-symbols/check-circle-outline-rounded'
import IconSchedule from '~icons/material-symbols/schedule-outline-rounded'

const props = defineProps<{
  data: BiometryResponse
  disabled: boolean
}>()

const emit = defineEmits<(e: 'methodReceipt', v: string) => void>()

const { t } = useI18n()
const method_receipt = ref('whatsapp')
const radios = [
  {
    name: 'method_receipt',
    value: 'whatsapp',
    label: t('customer.biometry.description.send_biometry_contract.radio.whatsapp'),
  },
  {
    name: 'method_receipt',
    value: 'sms',
    label: t('customer.biometry.description.send_biometry_contract.radio.sms'),
  },
]

const tag: Tags = {
  sent_message: {
    id: 'sent_message',
    text: t('customer.biometry.status.sent_message'),
    variant: 'fill',
    size: 'small',
    type: 'informative',
  },
  waiting: {
    id: 'waiting',
    text: t('customer.biometry.status.waiting'),
    variant: 'fill',
    size: 'small',
    type: 'neutral',
  },
  in_progress: {
    id: 'in_progress',
    text: t('customer.biometry.status.in_progress'),
    variant: 'fill',
    size: 'small',
    type: 'informative',
  },
  under_analysis: {
    id: 'under_analysis',
    text: t('customer.biometry.status.under_analysis'),
    variant: 'fill',
    size: 'small',
    type: 'informative',
  },
  reproved: {
    id: 'reproved',
    text: t('customer.biometry.status.reproved'),
    variant: 'fill',
    size: 'small',
    type: 'negative',
  },
  accomplished: {
    id: 'accomplished',
    text: t('customer.biometry.status.accomplished'),
    variant: 'fill',
    size: 'small',
    type: 'positive',
  },
  signed: {
    id: 'signed',
    text: t('customer.biometry.status.signed'),
    variant: 'fill',
    size: 'small',
    type: 'positive',
  },
  completed: {
    id: 'completed',
    text: t('customer.biometry.status.approved'),
    variant: 'fill',
    size: 'small',
    type: 'positive',
  },
}

const step = {
  send_biometry_contract: {
    key: 1,
    id: 'send_biometry_contract',
    text: t('customer.biometry.steps.send_contract'),
    status: tag.sent_message,
  },
  biometry: {
    key: 2,
    id: 'biometry',
    text: t('customer.biometry.steps.biometry'),
    status: tag.waiting,
  },
  contract: {
    key: 3,
    id: 'contract',
    text: t('customer.biometry.steps.contract'),
    status: tag.waiting,
  },
  approval: {
    key: 4,
    id: 'approval',
    text: t('customer.biometry.steps.approval'),
    status: tag.waiting,
  },
  reproved: {
    key: 5,
    id: 'reproved',
    text: t('customer.biometry.status.reproved'),
    status: tag.reproved,
  },
}

watch(
  props.data,
  async () => {
    if (props.data)
      setData(props.data)
  },
  { immediate: true },
)

watch(
  method_receipt,
  async () => {
    emit('methodReceipt', method_receipt.value)
  },
)

function setData(data: BiometryResponse) {
  method_receipt.value = data.send_biometry_contract.method_receipt
  step.biometry.status = data.biometry.status !== 'completed' ? tag[data.biometry.status] || tag.waiting : tag.accomplished
  step.contract.status = data.contract.status !== 'completed' ? tag[data.contract.status] || tag.waiting : tag.signed
  step.approval.status = tag[data.approval] || tag.waiting
}

const isReproved = computed(() => props.data.status === 'reproved' && props.data.biometry.status !== 'reproved')
</script>

<template>
  <ol class="steps-core -column">
    <!-- step send contract -->
    <li class="step">
      <!-- left -->
      <div class="element">
        <div class="line" />

        <div class="link">
          <div class="circle shadow-weak">
            <span>{{ step.send_biometry_contract.key }}</span>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="link mb-7">
        <div class="label">
          <div class="flex items-center">
            <div class="title font-bold text-xs text-brand-primary-pure -mt-1">
              {{ step.send_biometry_contract.text }}
            </div>

            <template v-if="isReproved">
              <SolTag
                :id="step.reproved.id"
                :text="step.reproved.text"
                :variant="step.reproved.status.variant"
                :size="step.reproved.status.size"
                :type="step.reproved.status.type"
                class="ml-2"
              />
            </template>
            <template v-else>
              <SolTag
                v-if="data.biometry.status !== 'waiting'"
                :id="step.send_biometry_contract.id"
                :text="step.send_biometry_contract.status.text"
                :variant="step.send_biometry_contract.status.variant"
                :size="step.send_biometry_contract.status.size"
                :type="step.send_biometry_contract.status.type"
                class="ml-2"
              />
            </template>
          </div>

          <div class="subtitle">
            <ul class="list-disc ml-5 my-3">
              <li>{{ t('customer.biometry.description.send_biometry_contract.days', { days: data.remaining_contract_days_until_expiration }) }}</li>
              <li>{{ t('customer.biometry.description.send_biometry_contract.minutes', { minutes: data.send_biometry_contract.message.time }) }}</li>
              <li>{{ t('customer.biometry.description.send_biometry_contract.sign') }}</li>
            </ul>
          </div>

          <div
            class="title font-bold text-brand-primary-pure"
            :class="{ 'text-brand-primary-light': disabled || data.biometry.status !== 'waiting' }"
          >
            {{ t('customer.biometry.description.send_biometry_contract.radio.label') }}
          </div>

          <div class="flex items-center gap-4 -mt-1">
            <SolRadioGroup
              id="method_receipt"
              v-model="method_receipt"
              name="method_receipt"
              direction="row"
              title=""
              :disabled="disabled || data.biometry.status !== 'waiting'"
              :class="{ 'pointer-events-none': disabled || data.biometry.status !== 'waiting' }"
              :radios="radios"
            />
          </div>
        </div>
      </div>
    </li>

    <!-- step biometry -->
    <li class="step">
      <!-- left -->
      <div class="element">
        <div class="line" />

        <div class="link">
          <div class="circle shadow-weak">
            <span>{{ step.biometry.key }}</span>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="link mb-7">
        <div class="label">
          <div class="flex items-center">
            <div class="title font-bold text-xs text-brand-primary-pure -mt-1">
              {{ step.biometry.text }}
            </div>

            <SolTag
              :id="step.biometry.id"
              :text="step.biometry.status.text"
              :variant="step.biometry.status.variant"
              :size="step.biometry.status.size"
              :type="step.biometry.status.type"
              class="ml-2"
            />
          </div>

          <div class="subtitle">
            <ul class="list-disc ml-5 my-3">
              <li>{{ t('customer.biometry.description.biometry') }}</li>
            </ul>
          </div>

          <div v-for="(item, index) in data.biometry.signers" :key="index">
            <div class="flex items-center my-1">
              <div class="mr-2">
                {{ item.name }}
              </div>
              <IconCheck v-if="item.checked && data.biometry.status !== 'reproved'" class="text-brand-secondary-pure" />
              <IconSchedule v-else-if="data.biometry.status !== 'reproved'" />
            </div>
          </div>
        </div>
      </div>
    </li>

    <!-- step contract -->
    <li class="step">
      <!-- left -->
      <div class="element">
        <div class="line" />

        <div class="link">
          <div class="circle shadow-weak">
            <span>{{ step.contract.key }}</span>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="link mb-7">
        <div class="label">
          <div class="flex items-center">
            <div class="title font-bold text-xs text-brand-primary-pure -mt-1">
              {{ step.contract.text }}
            </div>

            <SolTag
              :id="step.contract.id"
              :text="step.contract.status.text"
              :variant="step.contract.status.variant"
              :size="step.contract.status.size"
              :type="step.contract.status.type"
              class="ml-2"
            />
          </div>

          <div class="subtitle">
            <ul class="list-disc ml-5 my-3">
              <li>{{ t('customer.biometry.description.contract') }}</li>
            </ul>
          </div>

          <div v-for="(item, index) in data.contract.signers" :key="index">
            <div class="flex items-center my-1">
              <div class="mr-2">
                {{ item.name }}
              </div>
              <IconCheck v-if="item.checked" class="text-brand-secondary-pure" />
              <IconSchedule v-else />
            </div>
          </div>
        </div>
      </div>
    </li>

    <!-- step approval -->
    <li class="step">
      <!-- left -->
      <div class="element">
        <div class="line" />

        <div class="link">
          <div class="circle shadow-weak">
            <span>{{ step.approval.key }}</span>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="link mb-7">
        <div class="label">
          <div class="flex items-center">
            <div class="title font-bold text-xs text-brand-primary-pure -mt-1">
              {{ step.approval.text }}
            </div>

            <SolTag
              :id="step.approval.id"
              :text="step.approval.status.text"
              :variant="step.approval.status.variant"
              :size="step.approval.status.size"
              :type="step.approval.status.type"
              class="ml-2"
            />
          </div>

          <div class="subtitle">
            <ul class="list-disc ml-5 my-3">
              <li>{{ t('customer.biometry.description.approval') }}</li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  </ol>
</template>

<style lang="scss" scoped>
.steps-core {
  @apply flex justify-between flex-shrink-0;

  > .step {
    @apply relative;
    @apply w-full;

    &:last-of-type > .element > .line {
      @apply hidden;
    }
  }

  > .step > .element {
    @apply flex justify-center relative;

    > .link {
      @apply w-fit-content h-fit-content pointer-events-none;
    }

    > .link > .circle {
      @apply flex justify-center items-center;
      @apply w-xs h-xs rounded-half;
      @apply overflow-hidden;
      @apply pointer-events-none;
    }

    > .line {
      @apply absolute;
      @apply top-4xs;
      @apply bg-neutral-high-dark;

      height: 1px;
      left: calc(50% + 16px);
      width: calc(100% - 32px);
    }
  }

  &.-column {
    @apply flex-col h-full;

    > .step {
      @apply flex flex-grow;
    }

    > .step > .element > .line {
      @apply top-xs;

      left: 50%;
      width: 1px;
      height: calc(100% - 32px);
    }

    > .step > .link > .label {
      @apply mt-nano ml-nano;
      @apply text-left;
    }
  }
}
</style>
