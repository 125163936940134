export interface CustomerRegisterProject {
  project_name: string
  project_provider: ProviderEnum
}

export interface CustomerDataStatus {
  id: number
  created_at: string
  slug: 'under_analysis' | 'approved' | 'reproved' | 'pending'
  canceled_at: string | null
  reason: string | null
  updated_at: string
}

export interface Options {
  name: string
  combo_id: string
}

export interface Provider {
  name: string
  id: number
}

export interface OptionsModel {
  id: number
  created_at: string
  canceled_at: string | null
  name: string
  updated_at: string
}

export interface ClientDataOptions {
  nationality: Options[]
  occupation: Options[]
  gender: Options[]
}

export interface AddressOptions {
  state: Options[]
}

export enum ProviderEnum {
  SANTANDER = 'santander',
  SOLFACIL = 'solfacil',
}

export interface Address {
  street?: string
  number?: number
  complement?: string
  neighborhood?: string
  city: {
    name: string
    combo_id: string
  }
  state: {
    name: string
    combo_id: string
  }
}

export interface AddressFormModel {
  street_number: string
  rural_area?: boolean
  id: string
  neighborhood: string
  created_at: string
  canceled_at: string | null
  street: string
  postal_code: string
  additional_info: string
  city_id: string
  state_id: string
  address_id: string | null
  updated_at: string
  city: {
    name: string
    id: string
  }
  state: {
    name: string
    id: string
  }
}

export interface PersonFormModel {
  email: string
  cpf: string
  mother_name: string
  nationality_id: string
  occupation_id: string
  created_at: string
  updated_at: string
  full_name: string
  id: string
  phone: string
  birthdate: string
  gender_id: string
  person_id: string | null
  canceled_at: string | null
  nationality: OptionsModel
  gender: OptionsModel
  occupation: OptionsModel
}

export interface FinancingFormModel {
  project_name: string
  project_id: string
  external_id: string
  provider_id: string
  plant_address_form_id: string
  status_id: string
  updated_at: string
  id: string
  rural_area: string
  person_form_id: string
  residencial_address_form_id: string
  created_at: string
  canceled_at: string
  person_form: PersonFormModel
  plant_address_form: AddressFormModel
  residential_address_form: AddressFormModel
  provider: Provider
  installation_document: string
  status: string | CustomerDataStatus
  changes_after_confirmation: number
  remaining_changes_after_confirmation: number
}

// Form
export interface OptionsForm {
  name: string
  value: string
}
export interface PersonForm {
  birthdate: string
  motherName?: string
  nationality?: OptionsForm | null
  occupation?: OptionsForm | null
  gender?: OptionsForm | null
  cep: string
  city: OptionsForm
  complement: string
  document: string
  email: string
  localAddress: boolean
  name: string
  neighborhood: string
  number: string
  phone: string
  state: OptionsForm
  street: string
  installationCep?: string
  installationCity?: OptionsForm
  installationComplement?: string
  installationCountryside?: boolean
  installationNeighborhood?: string
  installationNumber?: string
  installationState?: OptionsForm
  installationStreet?: string
  electricityBill?: boolean
  electricityBillDocument?: string
}

export interface PersonFormRequest {
  full_name: string
  cpf?: string
  phone?: string
  email: string
  birthdate: string
  mother_name: string | null
  nationality_id: number | null
  occupation_id: number | null
  gender_id: number | null
}

export interface UpdateFormPartialResponse {
  project_id: string
  person_form: PersonFormRequest
  residential_is_the_same_of_plant: boolean
  residential_address_form: AddressFormRequest
  plant_address_form: AddressFormRequest
  installation_document: string | null
}

export interface AddressForm {
  postal_code: string
  street: string
  street_number: number
  additional_info: string
  neighborhood: string
  state_id: number
  city_id: number
  city: {
    name: string
    value: string
  }
  state: {
    name: string
    value: string
  }
}

export interface AddressFormRequest {
  postal_code?: string
  street?: string
  street_number?: number
  additional_info?: string
  neighborhood?: string
  state_id: number | null
  city_id: number | null
  rural_area?: boolean
}

export interface ResponseUpdatePerson {
  data: {
    invalid_fields?: string
  }
}
export interface ProjectData {
  distributor: OptionsForm[]
  phases: OptionsForm[]
  inverters: OptionsForm[]
  modules: OptionsForm[]
  microinverters: OptionsForm[]
}

export enum InverterTypeEnum {
  STRING = 'string',
  MICROINVERTER = 'microinverter',
}

export interface Equipment {
  quantity: number
  name: string
  id: number
}
export interface ProjectDataForm {
  distributor: OptionsForm | null
  phase: OptionsForm | null
  inverter_type: InverterTypeEnum
  inverters: Equipment[]
  modules: Equipment[]
}

export interface TagStep {
  id: string
  text: string
  variant: 'fill' | 'outline' | undefined
  size: 'medium' | 'small' | undefined
  type: 'positive' | 'warning' | 'negative' | 'informative' | 'neutral' | undefined
}

export interface ClientDataStatusEnum {
  under_analysis: TagStep
  approved: TagStep
  reproved: TagStep
  pending: TagStep
  pendency: TagStep
}

export interface DocumentStatusEnum {
  pending: TagStep
  pendency: TagStep
  under_analysis: TagStep
  reproved: TagStep
  approved: TagStep
}

export interface ReceiptModelStatusEnum {
  in_analysis: TagStep
  approved: TagStep
  denied: TagStep
  in_progress: TagStep
  pending_change: TagStep
  selected: TagStep
  transfer_in_progress: TagStep
}

export interface ProjectStatusEnum {
  pending: TagStep
  submitted: TagStep
  in_progress: TagStep
  kit_pending: TagStep
  approved: TagStep
}

export interface Document {
  id: string
  type: string
  url: string
}

export interface DocumentModel {
  id: string
  status: string
  project_id: string
  installation_document_matches_bill: boolean
  identity_matches_document: boolean
  installation_address_matches_bill: boolean
  reason: string
  documents: Document[]
  comments: string
}

export type StatusEnumDocuments = 'pending' | 'processing' | 'failed' | 'done' | 'deleted'

export interface TypesErrorBill {
  documentMatchesBill: string
  addressMatchesBill: string
}

export type TagType = 'positive' | 'warning' | 'negative' | 'informative' | 'neutral'

export interface Tag {
  text: string
  type: TagType
}

export interface Step {
  key: string
  text: string
  current?: boolean
  failed?: boolean
}

export type StageFormalization = 'registration' | 'documentation' | 'receipt_model' | 'hardware' | 'contract'
