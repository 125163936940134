import type { UseModule } from './types'
import { VueQueryPlugin } from '@tanstack/vue-query'
/* eslint-disable no-console */
import { createApp } from 'vue'
import AppCom from './App.vue'
import environments from './plugins/environments'
import queryClient from './queryClient'

import { router, routes } from './router'
import './styles/main.scss'
import 'virtual:windi.css'
import 'virtual:windi-base.css'
import 'virtual:windi-components.css'
import 'virtual:windi-utilities.css'
import 'virtual:windi-devtools'
import 'virtual:cdn-solfacil'

if (import.meta.env.DEV) {
  if (window.__MOCK_API__)
    import('./services/mocks/Worker').then(({ worker }) => worker.start())
}

if (import.meta.env.MODE === 'production') {
  console.log('Production mode')
  import('./sentry')
}

console.log(`${import.meta.env.VITE_APP_VERSION ?? 'development'}`)

const container = document.querySelector('#app') as Element

const app = createApp(AppCom)
app.use(router)
app.use(environments)
app.use(VueQueryPlugin, { queryClient })

const modules = Object
  .values(import.meta.glob<{ install: UseModule }>('./modules/*.ts', { eager: true }))
  .map(async i => i.install?.({ instance: app, router, routes }))

Promise.all(modules).then(() => {
  setTimeout(() => app.mount(container), 300)
})
