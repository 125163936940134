<script setup lang="ts">
import IMaterialSymbolsCheckCircleRounded from '~icons/material-symbols/check-circle-rounded'
import IconPerson from '~icons/material-symbols/person-outline'
import IconStore from '~icons/material-symbols/store-outline'

interface OptionsButtonAmpera {
  title: string
  description?: string
  financing: {
    title: string
    description: string
  }
  bnpl: {
    title?: string
    description: string
  }
  key: string
  disabled?: boolean
}
const props = defineProps({
  options: { default: '', type: Array<OptionsButtonAmpera> },
  active: { default: '', type: String },
  disabled: { default: false, type: Boolean },
})
const emit = defineEmits<{
  (e: 'selected', v: string): void
}>()

function getClasses(btnOptions: any) {
  if (btnOptions.disabled)
    return 'btn-disabled'

  else if (btnOptions.key === props.active)
    return 'btn-selected'

  else
    return ''
}
</script>

<template>
  <div class="buttons">
    <div
      v-for="btn in options"
      :key="btn.key"
      disable
      class="btn-selectable"
      :data-testid="`btn-key-${btn.key}`"
      :class="getClasses(btn)"
      :title="btn.disabled ? 'Esta função não está disponível no momento' : ''"
      @click="btn.disabled ? null : emit('selected', btn.key)"
    >
      <div class="flex gap-2 items-center mb-3">
        <IconPerson v-if="btn.key.toLocaleUpperCase() === 'PF'" class="w-[45px] h-[45px]" />
        <IconStore v-if="btn.key.toLocaleUpperCase() === 'PJ'" class="w-[45px] h-[45px]" />
        <div>
          <h2 class="text-xs text-neutral-low-dark font-bold">
            {{ btn.title }}
          </h2>
          <span class="block text-2xs text-neutral-low-dark">{{ btn.description }}</span>
        </div>
      </div>
      <ul class="pl-[18px] text-neutral-low-light">
        <li class="list-disc mb-2">
          <span class="block text-2xs">{{ btn.financing.title }}</span>
          <span class="text-3xs">{{ btn.financing.description }}</span>
        </li>
        <li class="list-disc">
          <span
            v-if="btn.bnpl.title"
            class="block text-2xs"
          >
            {{ btn.bnpl.title }}
          </span>
          <span class="text-3xs">{{ btn.bnpl.description }}</span>
        </li>
      </ul>
      <IMaterialSymbolsCheckCircleRounded v-if="btn.key === active" class="icon-check" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buttons {
  @apply pt-6;
  @apply grid gap-4;
  @screen md:system {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
  > .btn-selectable {
    @apply relative;
    @apply p-4;
    @apply border-1 rounded-2xl border-neutral-high-medium;
    @apply hover:border-1 hover:border-neutral-low-pure cursor-pointer;

    > .icon-check {
      @apply absolute top-2 right-2;
      @apply text-brand-secondary-pure
      @apply text-xs;
    }
  }
  > .btn-selected {
    @apply border-1 border-neutral-low-pure;
  }
  > .btn-disabled {
    @apply cursor-not-allowed opacity-75;
    @apply hover:border-neutral-high-medium hover: border-2;
  }
}
</style>
