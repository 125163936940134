<script setup lang="ts">
import type { User } from '~/utils/auth/User'
import type { Addons, SimulationConclusion } from '~/utils/simulation/Simulation'
import { useToast } from '@solfacil/girassol'
import SimulatorService from '~/services/simulator/Simulator'
import useLegacyFinancing from '~/store-v2/legacy-financing/useLegacyFinancing'
import { Journey } from '~/types/enum'
import Modal from './Modal.vue'

const props = defineProps<{
  disabledFields: boolean
  loadingConclusion: boolean
  isOnflexPartner: boolean
}>()
const { createErrorToast, createSuccessToast } = useToast()
const { getSelectedInstallment, getValueOfAmpera, getAddonsApplieds, getCurrentProject, getCommissionApplied, getGracePeriodApplied } = useResumeStore()
const { track } = useMixpanel()

const { t } = useI18n()
const router = useRouter()
const user: User = useAppStorage().get('user')
const simulatorService = new SimulatorService(useApi('simulator'))

const blockFields = ref(false)
const loadingLocal = ref(false)

const disabledButton = ref(true)
const flagFinancing = ref(Journey.MIXED)
const showModalJourney = ref(false)

const config = import.meta.env

onUpdated(() => {
  disabledButton.value = !props.disabledFields || blockFields.value || !getSelectedInstallment() || loadingLocal.value
})

onMounted(async () => {
  const flagNewJourney = await useFlag('new_journey', { partner_id: user?.parceiro?.id })
  flagFinancing.value = Number(flagNewJourney)
})

const currentProject = getCurrentProject()

const { data: legacyFinancing, isLoading: isLoadingLegacyFinancing } = useLegacyFinancing(router.currentRoute.value.params.id as string, {
  skip: !currentProject?.complete,
})

async function submitFinancial(newJourney: boolean) {
  try {
    showModalJourney.value = false
    loadingLocal.value = true
    const selectedInstallment = getSelectedInstallment()
    const commission = getCommissionApplied()

    if (selectedInstallment === null || currentProject === null || commission === undefined)
      return

    const addonsAppliedId = getAddonsApplieds().map((addon: Addons) => {
      if (addon.applied)
        return addon.id

      return null
    }).filter((n: string | null) => n)

    const payload: SimulationConclusion = {
      id: selectedInstallment.engine_simulation_id,
      new_journey: newJourney,
      is_combo: selectedInstallment.combo,
      is_ampera: Boolean(getValueOfAmpera()),
      commission: selectedInstallment?.is_from_suggested ? Number(selectedInstallment.commission) : Number(commission),
      simulation_id: selectedInstallment.simulation_id,
      project_id: currentProject.id,
      applied_addons: addonsAppliedId as any[],
      is_from_suggested: selectedInstallment?.is_from_suggested,
    }

    const response = await simulatorService.create_financial(payload)

    createSuccessToast('Financiamento criado com sucesso.')
    if (response.data.new_journey) {
      track('simulation-detail_modal_nova-jornada', { trigger: 'Clique no botão de seguir com a nova jornada' })

      if (currentProject.person_type === 'PF')
        return router.push(`/customer/${currentProject.id}`)

      return router.push(`/company/${currentProject.id}`)
    }

    blockFields.value = true

    track('simulation-detail_modal_jornada-antiga', { trigger: 'Clique no botão de seguir com a jornada antiga' })
    window.open(`${config.VITE_URL_LEGACY}/financiamento/dados_do_cliente/${response.data.financing_id}`, '_self')
  }
  catch (e: any) {
    showModalJourney.value = false
    if (e.response.status === 422)
      createErrorToast('Selecione ao menos uma parcela.')
    else
      createErrorToast('Ocorreu um erro inesperado.')
  }
  finally {
    loadingLocal.value = false
  }
}

function setFlagFinancing() {
  const isOldJourney = flagFinancing.value === Journey.OLD
  const isMixedJourney = flagFinancing.value === Journey.MIXED
  const isNewJourney = flagFinancing.value === Journey.NEW

  if (isOldJourney) {
    submitFinancial(false)
    return
  }

  if (isMixedJourney) {
    if (props.isOnflexPartner) {
      submitFinancial(true)
      return
    }

    showModalJourney.value = true
    return
  }

  if (isNewJourney)
    submitFinancial(true)
}

function redirectFormalization() {
  if (legacyFinancing.value && !currentProject?.new_journey) {
    window.open(`${config.VITE_URL_LEGACY}/financiamento/etapas/${legacyFinancing.value.financingId}`, '_self')
    return
  }

  if (currentProject?.person_type === 'PF')
    return router.push(`/customer/${currentProject?.id}`)

  return router.push(`/company/${currentProject?.id}`)
}
</script>

<template>
  <div class="w-full justify-end">
    <div class="w-full flex gap-3 justify-end">
      <SimulationElementDownloadList
        v-if="!currentProject?.complete"
        :commission="Number(getCommissionApplied())"
        :grace-period="getGracePeriodApplied()"
        :is-combo="getSelectedInstallment()?.combo"
      />
      <SolButton
        v-if="currentProject?.complete"
        id="customer"
        size="large"
        :loading="isLoadingLegacyFinancing"
        @click="redirectFormalization"
      >
        {{ t('simulation.return_formalization') }}
      </SolButton>
      <SolButton
        v-if="!currentProject?.complete"
        id="go"
        :disabled="loadingConclusion"
        :loading="loadingLocal"
        size="large"
        @click="setFlagFinancing"
      >
        {{ t('simulation.conclude') }}
      </SolButton>
    </div>

    <Modal
      :open="showModalJourney"
      @new-journey="(value) => submitFinancial(value)"
      @close="showModalJourney = false"
    />
  </div>
</template>
