<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Tooltip text',
    },
    position: {
      default: 'top',
      type: String,
    },
  },
}
</script>

<template>
  <span :data-tooltip="text" :position="position"><slot /></span>
</template>

<style lang="scss" scoped>
$gray: #505050;

[data-tooltip] {
  & > * {
    @apply inline-block;
  }

  @apply relative;
  &:before,
  &:after {
    @apply transform-none text-micro pointer-events-none absolute hidden opacity-0;
    user-select: none;
  }
  &:before {
    @apply content-[''] border-transparent border-[5px] z-50;
  }
  &:after {
    @apply content-[attr(data-tooltip)] overflow-hidden p-4 rounded-md whitespace-pre;
    @apply bg-neutral-low-medium text-neutral-high-pure z-50 font-regular;
    @apply text-micro lg:site:text-3xs min-w-[230px];
  }
  &:hover:before,
  &:hover:after {
    @apply block;
  }
  /* position: TOP */
  &:not([position]):before,
  &[position^="top"]:before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: $gray;
  }
  &:not([position]):after,
  &[position^="top"]::after {
    bottom: calc(100% + 5px);
  }

  &:not([position])::before,
  &:not([position])::after,
  &[position^="top"]::before,
  &[position^="top"]::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
  }
  /* position: BOTTOM */
  &[position^="bottom"]::before {
    top: 105%;
    border-top-width: 0;
    border-bottom-color: $gray;
  }
  &[position^="bottom"]::after {
    top: calc(105% + 5px);
  }
  &[position^="bottom"]::before,
  &[position^="bottom"]::after {
    left: 50%;
    transform: translate(-50%, 0.5em);
  }
  /* position: LEFT */
  &[position^="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: $gray;
    left: calc(0em - 9px);
    transform: translate(-0.5em, -50%);
  }
  &[position^="left"]::after {
    top: 50%;
    right: calc(100% + 9px);
    transform: translate(-0.5em, -50%);
  }
  /* position: RIGHT */
  &[position^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: $gray;
    right: calc(0em - 5px);
    transform: translate(0.5em, -50%);
  }
  &[position^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(0.5em, -50%);
  }

  &:not([position]):hover::before,
  &:not([position]):hover::after,
  &[position^="top"]:hover::before,
  &[position^="top"]:hover::after,
  &[position^="bottom"]:hover::before,
  &[position^="bottom"]:hover::after {
    animation: tooltips-vert 100ms ease-out forwards;
  }

  &[position^="left"]:hover::before,
  &[position^="left"]:hover::after,
  &[position^="right"]:hover::before,
  &[position^="right"]:hover::after {
    animation: tooltips-horz 100ms ease-out forwards;
  }
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}
</style>
