<script setup lang="ts">
import type { User } from '~/utils/auth/User'
import type { Project } from '~/utils/project/Project'
import type { RequestSimulationOpportunities } from '~/utils/simulation/Simulation'
import SimulatorService from '~/services/simulator/Simulator'
import useFinancingV2 from '~/store-v2/financings/useFinancing'
import { getErrorMessage } from '~/utils/errors'

const { track } = useMixpanel()

const router = useRouter()

const id = ref(router.currentRoute.value.params.id as string)
const {
  error,
  isError,
  isLoading,
} = useFinancingV2(id)

const errorMessage = computed(() => getErrorMessage(error.value))

const { setProject } = useResumeStore()

const user: User = useAppStorage().get('user')

const simulatorService = new SimulatorService(useApi('simulator'))
const projectResponse = ref<Project | undefined>(undefined)

const loadingProject = ref(false)
const defaultCommission = ref(0)
const defaultCommissionRaw = ref(0)
const defaultGracePeriod = ref(1)
const defaultInstallment = ref(60)
const showCommissionMoney = ref(false)
const simulation = ref({})
const projectIsFinished = ref(false)
const riskRioGrandeDoSul = 9

const { setAmpera } = useAddonStore()
const { setSimulationOpportunities } = useResumeStore()

function convertAnualRateToMonthly(annualRate: number) {
  const annualRateDecimal = annualRate / 100
  const monthlyRateDecimal = (1 + annualRateDecimal) ** (1 / 12) - 1
  const monthlyRate = monthlyRateDecimal * 100
  return monthlyRate?.toFixed(2)?.replace('.', ',')
}

const cetValueFinished = ref('')
const projectShoppingCartId: any = ref('')

async function mountNewQuotas(project: Project): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      defaultCommission.value = project.selected_installments.commission
      defaultCommissionRaw.value = project.selected_installments?.commission_raw || 0
      defaultGracePeriod.value = project.selected_installments.grace_period
      defaultInstallment.value = project.selected_installments.installments
      projectIsFinished.value = project.complete
      simulation.value = project
      cetValueFinished.value = project?.selected_installments?.cet

      const addonAmpera = project.addons.filter(item => item.product_slug === 'Ampera')

      if (addonAmpera.length) {
        setAmpera(addonAmpera, addonAmpera[0].applied, project.can_select_ampera)
      }

      resolve()
    }
    catch (error) {
      reject(error)
    }
  })
}

async function mountOldQuotas(project?: Project, lastSimulation?: any): Promise<void> {
  return new Promise<void>((resolve) => {
    if (!project || !!lastSimulation.selected_installment) {
      resolve()
      return
    }

    const parsedSimulation = {
      ...lastSimulation,
      selected_installments: lastSimulation.selected_installment,
      taxa_de_juros: convertAnualRateToMonthly(lastSimulation.selected_installment.taxa_de_juros),
    }

    defaultCommission.value = lastSimulation.commission
    defaultCommissionRaw.value = lastSimulation?.commission_raw || 0
    defaultGracePeriod.value = lastSimulation.grace_period
    defaultInstallment.value = lastSimulation.installments
    projectIsFinished.value = project.complete
    simulation.value = parsedSimulation

    resolve()
  })
}

const lastSimulation: any = ref({})

async function getShoppingCartId() {
  try {
    const response = await simulatorService.get_simulation_by_shopping_cart_id(String(router.currentRoute.value.params.id))
    projectShoppingCartId.value = response.data
  }
  catch (error) {
    console.error(error)
    projectShoppingCartId.value = ''
  }
}

async function setOpportunities(project: Project) {
  const payload: RequestSimulationOpportunities = {
    partner_id: project.partner_id,
    financing_value: project.project_value - project.down_payment,
    project_type: project.person_type,
  }

  const solfacilPlusPoints = ref(false)

  if (project.partner_id)
    solfacilPlusPoints.value = await useFlag('show-solfacil-plus-points', { partner_id: project.partner_id })

  if (solfacilPlusPoints.value) {
    const response = await simulatorService.post_simulation_opportunities(payload)
    if (response.data)
      setSimulationOpportunities(response.data)
  }
}

const shoppingCartId = ref('')

onMounted(async () => {
  shoppingCartId.value = String(router.currentRoute.value.query.shopping_cart_id) !== 'undefined' ? String(router.currentRoute.value.query.shopping_cart_id) : ''
  track('simulation-list_page-view', { trigger: 'Ver página do detalhe da simulação - parcelas' })

  try {
    loadingProject.value = true
    showCommissionMoney.value = await useFlag('show-commission-money', { partner_id: user?.parceiro?.id })

    const { data: project } = await simulatorService.get_project(router.currentRoute.value.params.id)

    if (project?.project_status === 'INACTIVE' && !project.complete)
      return router.push('/')

    if (project) {
      projectResponse.value = project
      setOpportunities(project)

      setProject(project)
    }

    const flagStoreMarketFinancing = await useFlag('store-showcase', { partner_id: user?.parceiro?.id })
    if (flagStoreMarketFinancing)
      await getShoppingCartId()

    if (project && project.complete && project.selected_installments)
      return await mountNewQuotas(project)

    const { data } = await simulatorService.get_last_simulation_by_project(router.currentRoute.value.params.id)

    lastSimulation.value = data

    if (lastSimulation.value && lastSimulation.value.installments)
      await mountOldQuotas(project, lastSimulation)

    loadingProject.value = false
  }
  catch {
    loadingProject.value = false
  }
  finally {
    loadingProject.value = false
  }
})

const loading = computed(() => loadingProject.value || isLoading.value)
const mappingErrors = computed (() => ['NOT_FOUND', 'UNAUTHORIZED', 'UNKNOWN'].includes(errorMessage.value))
</script>

<template>
  <NavigationHeaderBar stage="simulation" :title="projectResponse?.project_name" />
  <div class="container">
    <SharedViewPartnerAlert />

    <div v-if="loading" class="installments-container-loader">
      <div class="loader-project" />
      <div class="loader-project hidden md:system:block" />
    </div>
    <template v-else>
      <template v-if="isError && mappingErrors">
        <SharedErrorsCheckAccess :error-message="errorMessage" />
      </template>
      <template v-else>
        <SolAlert
          v-if="projectResponse?.decision_pre_analisys === riskRioGrandeDoSul && !loadingProject"
          id="title"
          class="mb-4xs md:site:mb-10"
          :title="$t('simulation.info_risk.RS_title')"
          feedback="warning"
        >
          <div>
            <p>{{ $t('simulation.info_risk.RS_description_conditions') }}</p>
            <ul class="list-disc list-inside">
              <li> {{ $t('simulation.info_risk.RS_description_grace') }} </li>
              <li> {{ $t('simulation.info_risk.RS_description_eligible_area') }} </li>
            </ul>
          </div>
        </SolAlert>
        <SimulationDetails
          v-if="projectResponse"
          :simulation="simulation"
          :disable-fields="!projectIsFinished"
          :project="projectResponse"
          :default-installment="defaultInstallment"
          :default-grace-period="defaultGracePeriod"
          :default-commission="defaultCommission"
          :default-commission-raw="defaultCommissionRaw"
          :cet="projectIsFinished ? cetValueFinished : lastSimulation?.selected_installment?.cet"
          :shopping-cart-id="shoppingCartId || projectShoppingCartId"
          :show-commission-money="showCommissionMoney"
        />
        <div v-else class="my-4 card-container flex flex-col justify-between w-full align-middle py-lg text-center items-center">
          <img src="/images/not-found.png" class="not-found">
          <h1 class="fonts-heading-h1">
            {{ $t('noResults.title') }}
          </h1>
          <p class="fonts-subtitle-medium">
            {{ $t('noResults.description') }}
          </p>
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss">
.container {
  @apply flex flex-col items-start px-4xs pb-6 mt-6;
  @screen md:system {
    @apply p-12 mt-0;
  }
}

.installments-container-loader{
  @apply w-full block;
  @screen md:system {
    @apply grid gap-4;
    grid-template-columns: minmax(0, 1fr) 300px
  }
}

.loader-project {
  @apply h-96 rounded-lg mt-5;
  @apply top-0 right-0 bottom-0 left-0 z-50;
  background: rgba(#fff, 0.9)
    url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
    center center;
}

.card-container{
  @apply px-12 my-6 bg-neutral-high-pure rounded-lg;

}
</style>

<route lang="yaml">
meta:
  layout: simulation
    </route>
