import type { SimulatorService } from '~/services/simulator-v2/simulator.types'
import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useOpportunities({ financing_value, partner_id, project_type }: SimulatorService.GetOpportunitiesRequest) {
  const queryKey = queryKeys.OPPORTUNITIES({ financing_value, partner_id, project_type })

  const queryFn = async () => {
    const res = await financialBff('query')({
      getOpportunities: [{ params: { financing_value, partner_id, project_type } }, { financing_gained_points: true, addons_opportunities: {
        addon_type: true,
        product_slug: true,
        gained_points: true,
      } }],
    })

    return res.getOpportunities
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 3,
    retryDelay: 1000,
  })
}
