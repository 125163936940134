import FinancingFlow from '~/services/financingFlow/FinancingFlow'

const config = import.meta.env

export default {
  async financingFlow(id: string) {
    const financingFlowStore = useFinancingFlowStore()

    try {
      const financingFlow = new FinancingFlow(useApi('financingFlow'))
      let response

      do {
        response = await financingFlow.getFinancingFlow(id)

        if (response.id) {
          financingFlowStore.setFinancingFlow(response)
          return response
        }
        else {
          await new Promise(resolve => setTimeout(resolve, 3000))
        }
      } while (!response.id)
    }
    catch (error) {
      console.error(error)
    }
  },

  oldJourneyFinancingListRedirect(new_journey: boolean) {
    if (!new_journey) {
      window.open(`${config.VITE_URL_LEGACY}/financiamentos`, '_self')
      return true
    }

    return false
  },
}
