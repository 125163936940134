/* eslint-disable no-console */
import z from 'zod'

const envSchema = z.object({
  // app
  VITE_APP_VERSION: z.string().default('development'),

  // sentry
  VITE_SENTRY_DSN: z.string().optional(),

  // launch darkly
  VITE_LAUNCH_DARKLY_CLIENT_ID: z.string(),
  VITE_LAUNCH_DARKLY_PROJECT_KEY: z.string(),

  // keycloak
  VITE_KEYCLOAK_API_URL: z.string(),
  VITE_KEYCLOAK_CLIENT_ID: z.string(),
  VITE_KEYCLOAK_REALM: z.string(),

  // PERSON API
  VITE_PERSON_API_URL: z.string(),

  // SOLFACILPLUS API
  VITE_SOLFACILPLUS_API_URL: z.string(),

  // CORE API
  VITE_CORE_API_URL: z.string(),

  // FINANCIAL BFF
  VITE_FINANCIAL_BFF_API_URL: z.string(),

  // PAYMENT BFF
  VITE_PAYMENT_BFF_API_URL: z.string(),
})

const env = envSchema.parse(import.meta.env)

if (import.meta.env.MODE === 'development')
  console.log(env)

export default env

declare global {
  export interface ImportMetaEnv extends z.infer<typeof envSchema> {}
}
