import { useMutation } from '@tanstack/vue-query'
import KnowError from '~/exceptions/KnownError'
import queryClient, { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'
import { HTTP_STATUS_BAD_REQUEST } from '../../constants'

interface UseUpdateCustomerMutationData {
  email: string
  phone: string
  project_id: string
}

interface Person {
  partner_id: string
  email: string
  phone: string
}

export interface UseUpdateCompanyMutationData {
  project_id: string
  majority_partner: Person
  partners: Person[]
}

interface UseUpdateCustomerParams {
  project?: {
    id: string
    customerType: string
  }
}

const knowErrors = [
  'BIOMETRY_ALREADY_DONE',
  'FORMALIZATION_COMPLETED',
  'INVALID_STATUS',
  'MAX_TRIES',
  'MATRIX_RESTRICTIONS',
  'SAME_EMAIL_PHONE',
]

export function useUpdateCustomer(params?: UseUpdateCustomerParams) {
  const mutationFn = async (data: UseUpdateCustomerMutationData) => {
    const res = await financialBff('mutation')({
      customer: [{
        email: data.email,
        phone: data.phone,
        project_id: data.project_id,
      }, {
        message: true,
        status: true,
      }],
    })

    if (res.customer?.status === HTTP_STATUS_BAD_REQUEST) {
      if ((knowErrors).includes(res.customer.message)) {
        throw new KnowError(res.customer.message)
      }

      throw new Error('UNKNOW_ERROR')
    }

    return res
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.CUSTOMER.UPDATE,
    onSuccess() {
      if (!params?.project)
        return

      queryClient.invalidateQueries({
        queryKey: queryKeys.CUSTOMER.DATA({ id: params.project.id, projectPersonType: params.project.customerType }),
      })
    },
  })
}

export function useUpdateCompany(params?: UseUpdateCustomerParams) {
  const mutationFn = async (data: UseUpdateCompanyMutationData) => {
    const res = await financialBff('mutation')({
      company: [{
        project_id: data.project_id,
        majority_partner: data.majority_partner,
        partners: data.partners,
      }, {
        message: true,
        status: true,
      }],
    })

    if (res.company?.status === HTTP_STATUS_BAD_REQUEST) {
      if ((knowErrors).includes(res.company.message)) {
        throw new KnowError(res.company.message)
      }

      throw new Error('UNKNOW_ERROR')
    }

    return res
  }

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.CUSTOMER.UPDATE,
    onSuccess() {
      if (!params?.project)
        return

      queryClient.invalidateQueries({
        queryKey: queryKeys.CUSTOMER.DATA({ id: params.project.id, projectPersonType: params.project.customerType }),
      })
    },
  })
}

export type CustomerUpdateResponse = ReturnType<typeof useUpdateCustomer>['data']
export type CompanyUpdateResponse = ReturnType<typeof useUpdateCompany>['data']
