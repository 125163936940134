<script setup lang="ts">
import type { FinancingStatus } from '~/services-v2/financial-bff/financial-bff.types'
import type { CompanyFormModel, TypesErrorsCompanyDocuments } from '~/utils/company-register/CompanyRegister'
import type { BiometryAlertStatus } from '~/utils/customer-register/Biometry'
import type { StageFormalization } from '~/utils/customer-register/CustomerRegister'
import type { HardwareCombosModel } from '~/utils/installation/Installation'
import { defineComponent } from 'vue'
import CompanyBiometryData from '~/components/company/solfacil/BiometryData.vue'
import CompanyClientData from '~/components/company/solfacil/CompanyData.vue'
import CompanyDocumentData from '~/components/company/solfacil/DocumentData.vue'
import FormalizationReiceiptModel from '~/components/formalization/reiceipt-model/index.vue'
import { isStageReproved } from '~/services-v2/financial-bff/financial-bff.utils'
import useFinancingStatus from '~/store-v2/financings/useFinancingStatus'
import date from '~/utils/date'

const props = defineProps<{
  companyForm: CompanyFormModel
  triggerRefetch: () => Promise<void>
  projectName: string
  hardwareForm: {
    response?: HardwareCombosModel
    refetch: () => Promise<void>
  }
}>()

defineComponent({
  components: {
    CompanyClientData,
    CompanyBiometryData,
  },
})

const statusDocument = ref('' as string)
const { t } = useI18n()
const router = useRouter()
const id = router.currentRoute.value.params.id as string
const errorsCompanyDocuments = ref({} as TypesErrorsCompanyDocuments)
const biometryAlert = ref({
  pendencies: null,
  reproved: false,
} as unknown as BiometryAlertStatus)
const stage = ref('') as unknown as Ref<StageFormalization>
const stageReproved = ref('')

const {
  isLoading: isLoadingStatus,
  refetch: refetchStatus,
  data: financingStatus,
} = useFinancingStatus(ref(id))

const hasAlert = props.companyForm.status === 'reproved'
  || statusDocument.value === 'failed'
  || biometryAlert.value?.reproved
  || biometryAlert.value?.pendencies?.items?.length
  || shouldRenderMirrorPending()

function sendError(...args: unknown[]): void {
  errorsCompanyDocuments.value = args[0] as TypesErrorsCompanyDocuments
}

watch(() => financingStatus.value, async () => {
  if (financingStatus.value && financingStatus.value.section_statuses.formalization) {
    stageReproved.value = isStageReproved(financingStatus.value.section_statuses.formalization)
  }
})

const componentStage = ref({
  registration: {
    id: 'registration',
    name: 'CompanyClientData',
    component: CompanyClientData,
    props: {
      companyForm: props.companyForm,
      triggerRefetch: props.triggerRefetch,
      errorsCompanyDocuments,
      statusDocument,
    },
  },
  documentation: {
    id: 'documentation',
    name: 'CustomerDocumentData',
    component: CompanyDocumentData,
    props: {
      statusCompanyform: props.companyForm.status,
    },
  },
  receipt_model: {
    id: 'receipt_model',
    name: 'FormalizationReiceiptModel',
    component: FormalizationReiceiptModel,
    props: {},
  },
  contract: {
    id: 'contract',
    name: 'CompanyBiometryData',
    component: CompanyBiometryData,
    props: {},
  },
})

const components = ref([
  componentStage.value.registration,
  componentStage.value.documentation,
  componentStage.value.receipt_model,
  componentStage.value.contract,
])

function shouldRenderMirrorPending(): boolean {
  return Boolean(
    props.hardwareForm.response?.is_combo
    && props.hardwareForm.response.is_showcase === false
    && !props.hardwareForm.response?.mirror_metadata,
  )
}

watch(() => props.hardwareForm.response, async () => {
  let index = 0 as number
  const item = 'hardware'

  await components.value.forEach((component, key) => {
    if (component.id === item)
      index = key
  })

  components.value = components.value.filter(component => component.id !== item)

  await nextTick()
  components.value.splice(index, 0, componentStage.value[item])
})

function stageOpenCollapse(value: StageFormalization) {
  stage.value = value

  setTimeout(() => {
    const element = document.getElementById(`accordion_${value}`)
    if (element)
      element.scrollIntoView({ behavior: 'smooth' })
  }, 500)
}

function redirectPage(value: string) {
  const routes = {
    simulation: `/simulation/details/${id}`,
    installation: `/installation/${id}`,
    concluded: `/installation/${id}`,
  }

  if (routes[value])
    router.push(routes[value])
}

const disabledGoToInstallation = computed(() => {
  if (['installation', 'concluded'].includes(financingStatus?.value?.step || ''))
    return false

  return true
})
</script>

<template>
  <div v-if="!isLoadingStatus">
    <NavigationHeaderBar
      stage="formalization"
      :title="props.projectName"
      :project-status="financingStatus as FinancingStatus"
      @stage="stageOpenCollapse"
    />

    <div v-if="components" class="informations">
      <div v-if="hasAlert" class="my-4 mb-8">
        <SolAlert
          v-if="financingStatus?.status === 'canceled'"
          id="informative-feedback-canceledl"
          :title="t('customer.canceled.title')"
          feedback="error"
          class="my-4"
        >
          {{ t('customer.canceled.description') }}
        </SolAlert>

        <SolAlert
          v-if="props.companyForm.status === 'reproved' || statusDocument === 'failed'"
          id="informative-feedback-receipt-model"
          :title="t('customer.titleUnableContinuefinancing')"
          feedback="error"
          class="my-4"
        >
          {{ t('customer.descriptionUnableContinuefinancing') }}
        </SolAlert>

        <SolAlert
          v-if="biometryAlert?.reproved"
          id="reproved-feedback-biometry"
          :title="t('customer.biometry.alert.reproved.title')"
          feedback="error"
          class="my-4"
        >
          {{ t('customer.biometry.alert.reproved.subtitle') }}
        </SolAlert>

        <div v-for="(item, index) in biometryAlert?.pendencies?.items" :key="index">
          <SolAlert
            id="pendencies-feedback-biometry"
            class="my-4"
            :title="item.message"
            feedback="warning"
          >
            {{ t('customer.biometry.alert.pendencies',
                 { date: date(biometryAlert?.pendencies?.expired_at).format("DD/MM/YYYY") })
            }}
          </SolAlert>
        </div>

        <SolAlert
          v-if="shouldRenderMirrorPending()"
          id="kit-mirror-pending"
          :title="t('project_data.alerts.kit_mirror_pending.title')"
          feedback="warning"
          class="my-4"
        >
          {{ t('project_data.alerts.kit_mirror_pending.text') }}
        </SolAlert>
      </div>

      <component
        :is="component.component"
        v-for="(component, index) in components"
        :key="index"
        class="content-accordion"
        :position="index + 1"
        :status-project="financingStatus?.section_statuses?.formalization as any"
        :stage="stage"
        :stage-reproved="stageReproved"
        :form-data="props.hardwareForm.response"
        :refetch="props.hardwareForm.refetch"
        v-bind="component.props"
        status-companyform=""
        @errors-company-documents="sendError"
        @status-biometry="refetchStatus"
        @alert-biometry="value => biometryAlert = value as BiometryAlertStatus"
        @client-status-document-step="value => statusDocument = value as string"
      />
    </div>

    <div class="mx-4 md:system:mx-12">
      <SolDivider thickness="x-small" orientation="horizontal" class="my-5 mt-0 md:system:my-0" />

      <div class="flex flex-col md:system:flex-row flex-col-reverse justify-between gap-5 my-4 md:system:my-12">
        <SolButton
          id="customer-back-simulation"
          size="large"
          class="w-full md:system:w-auto"
          variant="secondary"
          @click="redirectPage('simulation')"
        >
          {{ t('customer.endButtons.backSimulation') }}
        </SolButton>

        <SolButton
          id="customer-next-installation"
          size="large"
          class="w-full md:system:w-auto"
          :disabled="disabledGoToInstallation"
          @click="redirectPage('installation')"
        >
          {{ t('customer.endButtons.nextInstallation') }}
        </SolButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.informations {
  @apply p-4 md:site:px-12 md:site:pt-8;
}

.content-accordion + .content-accordion {
  @apply mt-2 lg:system:mt-8;
}
</style>
